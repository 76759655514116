import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface INotificationForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface INotificationForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  AUserExtByUserId: any[];
  sbRolList: any[];
  ASirketAll: any[];
  BolgeBySirketId: any[];
  ASubeByBolgeId: any[];
  CategoryBySirket: any[];
  getNotificationSelectboxReadonly: any[];
  ChangeEnabledOf: any;
  SetValueOf: string;
  NotificationSendUsernames: any[];
  CustomPushNotification: string;
  Notify: boolean;
  isComp631485Visible: "visible" | "hidden";
  isComp949141Enabled: "enabled" | "disabled";
  isComp91369Enabled: "enabled" | "disabled";
}

export class NotificationForm_ScreenBase extends React.PureComponent<INotificationForm_ScreenProps, any> {
  notificationform_949141_value_kuikaSelectBoxRef: React.RefObject<any>;
  notificationform_91369_value_kuikaSelectBoxRef: React.RefObject<any>;
  notificationform_98218_value_kuikaSelectBoxRef: React.RefObject<any>;
  notificationform_468297_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "7a9e1d6f-39f4-4439-aff6-c4b3408b0101",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Yeni Bildirim" },
        { Id: 711940, PropertyName: "value", Value: "Şirket" },
        { Id: 949141, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 238379, PropertyName: "value", Value: "Bölge" },
        { Id: 91369, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 361617, PropertyName: "value", Value: "Şube" },
        { Id: 98218, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 903626, PropertyName: "value", Value: "Checklist Kategorisi" },
        { Id: 468297, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 999148, PropertyName: "value", Value: "Başlık" },
        { Id: 142827, PropertyName: "placeholder", Value: "Giriniz" },
        { Id: 492227, PropertyName: "value", Value: "Mesaj" },
        { Id: 965599, PropertyName: "placeholder", Value: "Giriniz" },
        { Id: 9544, PropertyName: "label", Value: "GÖNDER" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.notificationform_949141_value_kuikaSelectBoxRef = React.createRef();
    this.notificationform_91369_value_kuikaSelectBoxRef = React.createRef();
    this.notificationform_98218_value_kuikaSelectBoxRef = React.createRef();
    this.notificationform_468297_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      AUserExtByUserId: [],
      sbRolList: [],
      ASirketAll: [],
      BolgeBySirketId: [],
      ASubeByBolgeId: [],
      CategoryBySirket: [],
      getNotificationSelectboxReadonly: [],
      ChangeEnabledOf: "",
      SetValueOf: "",
      NotificationSendUsernames: [],
      CustomPushNotification: "",
      Notify: false,
      isComp631485Visible: "hidden",
      isComp949141Enabled: "enabled",
      isComp91369Enabled: "enabled"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("notificationform", "notificationform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.NotificationFormPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("notificationform", "notificationform");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "SubeAdmin", "BolgeAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("notificationform", "notificationform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.NotificationFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      notificationform_949141_value: this.state.MyContext?.at?.(0)?.sirketId ?? undefined,
      notificationform_91369_value: this.state.MyContext?.at?.(0)?.bolgeId ?? undefined,
      notificationform_98218_value: this.state.MyContext?.at?.(0)?.subeId ?? undefined,
      notificationform_468297_value: this.state.AUserExtByUserId?.at?.(0)?.kategoriId ?? undefined,
      notificationform_142827_value: this.state.AUserExtByUserId?.at?.(0)?.firstName ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  NotificationFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UserId ?? this.props.screenInputs.userid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "NotificationForm/NotificationFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.notificationform_949141_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.notificationform_949141_options = stateVars.ASirketAll;
    formVars.notificationform_91369_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.bolgeId : null;
    formVars.notificationform_91369_options = stateVars.BolgeBySirketId;
    formVars.notificationform_98218_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.subeId : null;
    formVars.notificationform_98218_options = stateVars.ASubeByBolgeId;
    stateVars.AUserExtByUserId = result?.data.aUserExtByUserId;
    formVars.notificationform_468297_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.kategoriId : null;
    formVars.notificationform_468297_options = stateVars.CategoryBySirket;
    formVars.notificationform_142827_value = ReactSystemFunctions.toString(
      this,
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.firstName : null
    );
    stateVars.sbRolList = result?.data.sbRolList;
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.notificationform_949141_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.notificationform_949141_options = stateVars.ASirketAll;
    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.notificationform_91369_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.bolgeId : null;
    formVars.notificationform_91369_options = stateVars.BolgeBySirketId;
    stateVars.ASubeByBolgeId = result?.data.aSubeByBolgeId;

    formVars.notificationform_98218_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.subeId : null;
    formVars.notificationform_98218_options = stateVars.ASubeByBolgeId;
    stateVars.CategoryBySirket = result?.data.categoryBySirket;

    formVars.notificationform_468297_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.kategoriId : null;
    formVars.notificationform_468297_options = stateVars.CategoryBySirket;
    stateVars.getNotificationSelectboxReadonly = result?.data.getNotificationSelectboxReadonly;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.NotificationFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NotificationFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getNotificationSelectboxReadonly?.length > 0
            ? this.state.getNotificationSelectboxReadonly[0]?.sirketSelectboxReadonly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp949141Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.NotificationFormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.NotificationFormPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  NotificationFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getNotificationSelectboxReadonly?.length > 0
            ? this.state.getNotificationSelectboxReadonly[0]?.bolgeSelectboxReadonly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp91369Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.NotificationFormPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.NotificationFormPageInit3_();
      });
    }

    return isErrorOccurred;
  };
  NotificationFormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getNotificationSelectboxReadonly?.length > 0
            ? this.state.getNotificationSelectboxReadonly[0]?.subeSelectboxReadonly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp949141Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.NotificationFormPageInit4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.NotificationFormPageInit4_();
      });
    }

    return isErrorOccurred;
  };
  NotificationFormPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.notificationform_949141_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null
    );

    stateVars.dataSource_949141 = this.state.ASirketAll;
    formVars.notificationform_91369_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null
    );

    stateVars.dataSource_91369 = this.state.BolgeBySirketId;
    formVars.notificationform_98218_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null
    );

    stateVars.dataSource_98218 = this.state.ASubeByBolgeId;
    formVars.notificationform_468297_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.kategoriId : null
    );

    stateVars.dataSource_468297 = this.state.CategoryBySirket;
    formVars.notificationform_142827_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.firstName : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  NotificationFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NotificationFormComponent_949141_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "notificationform_949141_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "NotificationForm/NotificationFormComponent_949141_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.notificationform_91369_options = stateVars.BolgeBySirketId;
    stateVars.CategoryBySirket = result?.data.categoryBySirket;

    formVars.notificationform_468297_options = stateVars.CategoryBySirket;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.NotificationFormComponent_949141_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NotificationFormComponent_949141_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "notificationform_91369_value",
      null,
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "notificationform_98218_value",
      null,
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "notificationform_468297_value",
      null,
      null,
      "Id"
    );

    stateVars.dataSource_91369 = this.state.BolgeBySirketId;

    stateVars.dataSource_468297 = this.state.CategoryBySirket;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NotificationFormComponent_91369_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ),
      message: "*",
      formName: "notificationform_91369_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_91369_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "NotificationForm/NotificationFormComponent_91369_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ASubeByBolgeId = result?.data.aSubeByBolgeId;

    formVars.notificationform_98218_options = stateVars.ASubeByBolgeId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.NotificationFormComponent_91369_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NotificationFormComponent_91369_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "notificationform_98218_value",
      null,
      null,
      "Id"
    );

    stateVars.dataSource_98218 = this.state.ASubeByBolgeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NotificationFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "notificationform_949141_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ),
      message: "*",
      formName: "notificationform_91369_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "notificationform_98218_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.subeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "notificationform_142827_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "notificationform_142827_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      BolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_91369_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        "Guid"
      ),
      title_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "notificationform_142827_value", "value", "", "", "")
        ),
        "string"
      ),
      messages_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "notificationform_965599_value", "value", "", "", "")
        ),
        "string"
      ),
      backendUrl_1: ReactSystemFunctions.convertToTypeByName("https://avane.platform.kuika.com/mobilv1-api", "string"),
      screenIdForMobile_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      screenInputForMobile_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      screenInputNameForMobile_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "NotificationForm/NotificationFormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NotificationSendUsernames = result?.data.notificationSendUsernames;
    stateVars.CustomPushNotification = result?.data.customPushNotification;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.NotificationFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NotificationFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "bbf86328_5fa3_5909_8b2a_a0e9be3ee844_notify",
        this.defaultML,
        "Notification Gönderildi"
      ),
      "success",
      "bottom-right",
      1
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
