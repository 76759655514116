import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { BolgeList_Screen } from "../screens/bolgelist/bolgelist";
import { TodayCevap_Screen } from "../screens/todaycevap/todaycevap";
import { GerekceList_Screen } from "../screens/gerekcelist/gerekcelist";
import { SirketForm_Screen } from "../screens/sirketform/sirketform";
import { CategoryForm_Screen } from "../screens/categoryform/categoryform";
import { Signin_Screen } from "../screens/signin/signin";
import { Today_Screen } from "../screens/today/today";
import { SoruList_Screen } from "../screens/sorulist/sorulist";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { ClList_Screen } from "../screens/cllist/cllist";
import { DenetimDuzeltme_Screen } from "../screens/denetimduzeltme/denetimduzeltme";
import { Denetim_Screen } from "../screens/denetim/denetim";
import { ClReadOnly_Screen } from "../screens/clreadonly/clreadonly";
import { SubeForm_Screen } from "../screens/subeform/subeform";
import { NotificationList_Screen } from "../screens/notificationlist/notificationlist";
import { SubeList_Screen } from "../screens/subelist/subelist";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { HomePagecopy_Screen } from "../screens/homepagecopy/homepagecopy";
import { SoruForm_Screen } from "../screens/soruform/soruform";
import { ClForm_Screen } from "../screens/clform/clform";
import { KullaniciForm_Screen } from "../screens/kullaniciform/kullaniciform";
import { BolgeForm_Screen } from "../screens/bolgeform/bolgeform";
import { DenetimForm_Screen } from "../screens/denetimform/denetimform";
import { DenetimTarihce_Screen } from "../screens/denetimtarihce/denetimtarihce";
import { DenetimNotlar_Screen } from "../screens/denetimnotlar/denetimnotlar";
import { SirketList_Screen } from "../screens/sirketlist/sirketlist";
import { NotificationForm_Screen } from "../screens/notificationform/notificationform";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { CategoryList_Screen } from "../screens/categorylist/categorylist";
import { KullaniciList_Screen } from "../screens/kullanicilist/kullanicilist";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { ClSoruEkleme_Screen } from "../screens/clsoruekleme/clsoruekleme";
import { GerekceForm_Screen } from "../screens/gerekceform/gerekceform";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={HomePage_Screen} />
                        <PrivateRoute exact path="/bolgelist" component={BolgeList_Screen} />
            <PrivateRoute exact path="/todaycevap" component={TodayCevap_Screen} />
            <PrivateRoute exact path="/gerekcelist" component={GerekceList_Screen} />
            <PrivateRoute exact path="/sirketform" component={SirketForm_Screen} />
            <PrivateRoute exact path="/categoryform" component={CategoryForm_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/today" component={Today_Screen} />
            <PrivateRoute exact path="/sorulist" component={SoruList_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/cllist" component={ClList_Screen} />
            <PrivateRoute exact path="/denetimduzeltme" component={DenetimDuzeltme_Screen} />
            <PrivateRoute exact path="/denetim" component={Denetim_Screen} />
            <PrivateRoute exact path="/clreadonly" component={ClReadOnly_Screen} />
            <PrivateRoute exact path="/subeform" component={SubeForm_Screen} />
            <PrivateRoute exact path="/notificationlist" component={NotificationList_Screen} />
            <PrivateRoute exact path="/subelist" component={SubeList_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/homepagecopy" component={HomePagecopy_Screen} />
            <PrivateRoute exact path="/soruform" component={SoruForm_Screen} />
            <PrivateRoute exact path="/clform" component={ClForm_Screen} />
            <PrivateRoute exact path="/kullaniciform" component={KullaniciForm_Screen} />
            <PrivateRoute exact path="/bolgeform" component={BolgeForm_Screen} />
            <PrivateRoute exact path="/denetimform" component={DenetimForm_Screen} />
            <PrivateRoute exact path="/denetimtarihce" component={DenetimTarihce_Screen} />
            <PrivateRoute exact path="/denetimnotlar" component={DenetimNotlar_Screen} />
            <PrivateRoute exact path="/sirketlist" component={SirketList_Screen} />
            <PrivateRoute exact path="/notificationform" component={NotificationForm_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <PrivateRoute exact path="/categorylist" component={CategoryList_Screen} />
            <PrivateRoute exact path="/kullanicilist" component={KullaniciList_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/clsoruekleme" component={ClSoruEkleme_Screen} />
            <PrivateRoute exact path="/gerekceform" component={GerekceForm_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
