import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISoruForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISoruForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  ASoruSelectById: any[];
  ASirketSelectById: any[];
  ASoruSave: number;
  isCompsoruform_32679AuthorizationVisible: "visible" | "hidden";
}

export class SoruForm_ScreenBase extends React.PureComponent<ISoruForm_ScreenProps, any> {
  soruform_204880_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "a4023993-4b91-419a-ab5e-0b71c9b08706",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Soru Form" },
        { Id: 407389, PropertyName: "value", Value: "Şirket" },
        { Id: 53081, PropertyName: "value", Value: "Metin" },
        { Id: 48422, PropertyName: "placeholder", Value: "Soruyu yazınız..." },
        { Id: 5869063, PropertyName: "value", Value: "Beklenen Cevap Şekli" },
        { Id: 204880, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.soruform_204880_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ASoruSelectById: [],
      ASirketSelectById: [],
      ASoruSave: 0,
      dataSource_204880: [
        { key: "EvetHayir", text: "Evet / Hayır" },
        { key: "Resimli", text: "Resimli" },
        { key: "Aciklamali", text: "Açıklamalı" },
        { key: "Imza", text: "İmza" },
        { key: "FileUpload", text: "File Upload" }
      ],
      isCompsoruform_32679AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("soruform", "soruform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SoruFormPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsoruform_32679AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("soruform", "soruform");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("soruform", "soruform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SoruFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      soruform_48422_value: this.state.ASoruSelectById?.at?.(0)?.metin ?? undefined,
      soruform_204880_value: this.state.ASoruSelectById?.at?.(0)?.soruTipi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SoruFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      soruId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.soruId ?? this.props.screenInputs.soruid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.sirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SoruForm/SoruFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ASoruSelectById = result?.data.aSoruSelectById;
    formVars.soruform_48422_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.metin : null
    );
    formVars.soruform_204880_value =
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null;
    stateVars.ASirketSelectById = result?.data.aSirketSelectById;
    formVars.soruform_25527_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASirketSelectById?.length > 0 ? stateVars.ASirketSelectById[0]?.tanim : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SoruFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SoruFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.soruform_25527_value = ReactSystemFunctions.toString(
      this,
      this.state.ASirketSelectById?.length > 0 ? this.state.ASirketSelectById[0]?.tanim : null
    );

    formVars.soruform_48422_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.metin : null
    );

    formVars.soruform_204880_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SoruFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SoruFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "soruform_48422_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "soruform_48422_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "soruform_204880_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "soruform_204880_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AktifMi_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Metin_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "soruform_48422_value", "value", "", "", "")
        ),
        "string"
      ),
      SoruTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "soruform_204880_value", "value", "", "key", "")
        ),
        "string"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.sirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.soruId ?? this.props.screenInputs.soruid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SoruForm/SoruFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ASoruSave = result?.data.aSoruSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SoruFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SoruFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
