import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  MyContext: any[];
  ASirketAll: any[];
  UserSearch: any[];
  UserSearchCount: any[];
  DisableUser: any;
  AUserExtDelete: number;
  isComp505853Visible: "visible" | "hidden";
  isCompkullanicilist_946721AuthorizationVisible: "visible" | "hidden";
  isCompkullanicilist_311581AuthorizationVisible: "visible" | "hidden";
  isCompkullanicilist_461086AuthorizationVisible: "visible" | "hidden";
}

export class KullaniciList_ScreenBase extends React.PureComponent<IKullaniciList_ScreenProps, any> {
  kullanicilist_946721_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullanicilist_399769_value_kuikaTableRef: React.RefObject<any>;
  kullanicilist_402793_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "caf3fc7e-10f7-4ea9-abda-d2ed630d5418",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Kullanıcı Listesi" },
        { Id: 946721, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ KULLANICI" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 526906, PropertyName: "title", Value: "Şirket / Bölge / Şube" },
        { Id: 879989, PropertyName: "value", Value: "[datafield:area]" },
        { Id: 636504, PropertyName: "title", Value: "Rol" },
        { Id: 814112, PropertyName: "value", Value: "[datafield:rolename]" },
        { Id: 311581, PropertyName: "value", Value: "-" },
        { Id: 461086, PropertyName: "value", Value: "[datafield:kategoritanim]" },
        { Id: 2360440, PropertyName: "title", Value: "Kullanıcı Adı" },
        { Id: 7963713, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 184357, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 50480, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 696332, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 572572, PropertyName: "title", Value: "Aktif" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.kullanicilist_946721_value_kuikaSelectBoxRef = React.createRef();
    this.kullanicilist_399769_value_kuikaTableRef = React.createRef();
    this.kullanicilist_402793_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      MyContext: [],
      ASirketAll: [],
      UserSearch: [],
      UserSearchCount: [],
      DisableUser: "",
      AUserExtDelete: "",
      isComp505853Visible: "hidden",
      isCompkullanicilist_946721AuthorizationVisible: "visible",
      isCompkullanicilist_311581AuthorizationVisible: "visible",
      isCompkullanicilist_461086AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "kullanicilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KullaniciListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullanicilist_946721AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullanicilist_311581AuthorizationVisible", [
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "Sube"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullanicilist_461086AuthorizationVisible", [
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "Sube"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "kullanicilist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "SubeAdmin", "BolgeAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullanicilist", "kullanicilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KullaniciListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kullanicilist_946721_value: this.state.MyContext?.at?.(0)?.sirketId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  KullaniciListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      sirketId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KullaniciList/KullaniciListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.kullanicilist_916840_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    formVars.kullanicilist_946721_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.kullanicilist_946721_options = stateVars.ASirketAll;
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.kullanicilist_946721_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.kullanicilist_946721_options = stateVars.ASirketAll;
    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserSearchCount = result?.data.userSearchCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.kullanicilist_916840_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    formVars.kullanicilist_946721_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null
    );

    stateVars.dataSource_946721 = this.state.ASirketAll;

    stateVars.dataSource_399769 = this.state.UserSearch;
    formVars.kullanicilist_402793_total = ReactSystemFunctions.value(
      this,
      this.state.UserSearchCount?.length > 0 ? this.state.UserSearchCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KullaniciListComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KullaniciList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "726899",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_26846_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KullaniciList",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_946721_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_946721_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserSearchCount = result?.data.userSearchCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_946721_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_946721_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.UserSearch;
    formVars.kullanicilist_402793_total = ReactSystemFunctions.value(
      this,
      this.state.UserSearchCount?.length > 0 ? this.state.UserSearchCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("KullaniciForm", "UserId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("KullaniciForm", "NewEdit", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.KullaniciListComponent_814413_onClick1_,
      "KullaniciList",
      "KullaniciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "224578",
      null,
      "right",
      null,
      "450px",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  KullaniciListComponent_814413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_814413_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserSearchCount = result?.data.userSearchCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_814413_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_814413_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.UserSearch;
    formVars.kullanicilist_402793_total = ReactSystemFunctions.value(
      this,
      this.state.UserSearchCount?.length > 0 ? this.state.UserSearchCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KullaniciForm",
      "UserId",
      ReactSystemFunctions.value(this, "kullanicilist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("KullaniciForm", "NewEdit", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.KullaniciListComponent_860665_onClick1_,
      "KullaniciList",
      "KullaniciForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  KullaniciListComponent_860665_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      subeId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_860665_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserSearch = result?.data.userSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_860665_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_860665_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.UserSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "9251619b_4fab_7e7c_793e_6550a3a1b756_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);

        stateVars.DisableUser = await ReactSystemFunctions.disableUser(
          ReactSystemFunctions.value(this, "kullanicilist_399769_value", "userName")
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.KullaniciListComponent_505853_onClick1_();
        });
      }
    });

    return isErrorOccurred;
  };
  KullaniciListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "kullanicilist_399769_value", "id"),
        "Guid"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      subeId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_946721_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      bolgeId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      subeId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_505853_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AUserExtDelete = result?.data.aUserExtDelete;
    stateVars.UserSearch = result?.data.userSearch;

    stateVars.UserSearchCount = result?.data.userSearchCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_505853_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_505853_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.UserSearch;
    formVars.kullanicilist_402793_total = ReactSystemFunctions.value(
      this,
      this.state.UserSearchCount?.length > 0 ? this.state.UserSearchCount[0]?.adet : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciListComponent_402793_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicilist_402793_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciList/KullaniciListComponent_402793_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserSearch = result?.data.userSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciListComponent_402793_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciListComponent_402793_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.UserSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [726899] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KullaniciListPageInit();
    }
    if (diId == 224578) {
      isErrorOccurred = await this.KullaniciListComponent_814413_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 256479) {
      isErrorOccurred = await this.KullaniciListComponent_860665_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
