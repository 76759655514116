import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  SubeAdminStatistics: any[];
  SubeAdminKategoriler: any[];
  SubeUserStatistics: any[];
  AvaneAdmin: any[];
  DenetciStatistics: any[];
  DenetciSubeler: any[];
  SirketAdminStatistics: any[];
  SirketAdminBolgeler: any[];
  SirketAdminSubeler: any[];
  BolgeAdminStatistics: any[];
  BolgeAdminSubeler: any[];
  NAVIGATE: any;
  isComphomepage_478649AuthorizationVisible: "visible" | "hidden";
  isComphomepage_614685AuthorizationVisible: "visible" | "hidden";
  isComphomepage_227012AuthorizationVisible: "visible" | "hidden";
  isComphomepage_55134AuthorizationVisible: "visible" | "hidden";
  isComphomepage_210626AuthorizationVisible: "visible" | "hidden";
  isComphomepage_86436AuthorizationVisible: "visible" | "hidden";
  isComphomepage_57559AuthorizationVisible: "visible" | "hidden";
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  homepage_793690_value_kuikaGalleryViewRef: React.RefObject<any>;
  homepage_738574_value_kuikaTableRef: React.RefObject<any>;
  homepage_855062_value_kuikaTableRef: React.RefObject<any>;
  homepage_881650_value_kuikaTableRef: React.RefObject<any>;
  homepage_479985_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6c9235a0-b07a-4844-b001-a82a5fb7714c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 964623, PropertyName: "value", Value: "Pano" },
        { Id: 322594, PropertyName: "value", Value: "Günlük Toplam Görev Sayısı" },
        { Id: 790134, PropertyName: "value", Value: "Günlük Bekleyen Görev Sayısı" },
        { Id: 699392, PropertyName: "value", Value: "Başlangıç Zamanı Gelen Görev Sayısı" },
        { Id: 521529, PropertyName: "value", Value: "Aktif Görev Sayısı" },
        { Id: 579696, PropertyName: "value", Value: "Haftalık Ortalama" },
        { Id: 668911, PropertyName: "value", Value: "Günlük Zamanında Tamamlanan Görev Sayısı" },
        { Id: 600557, PropertyName: "value", Value: "Günlük Zamanında Tamamlanmayan Görev Sayısı" },
        { Id: 327323, PropertyName: "value", Value: "Günlük Gerekçeli Tamamlanan Görev Sayısı" },
        { Id: 422561, PropertyName: "value", Value: "Günlük Geç Tamamlanan Görev Sayısı" },
        { Id: 417988, PropertyName: "value", Value: "Aylık Ortalama" },
        { Id: 281993, PropertyName: "value", Value: "KATEGORİ BAZLI HAFTALIK VE AYLIK ORTALAMALAR" },
        { Id: 793690, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı..." },
        { Id: 689699, PropertyName: "value", Value: "[datafield:kategori]" },
        { Id: 469946, PropertyName: "value", Value: "Haftalık / Aylık" },
        { Id: 843450, PropertyName: "value", Value: "[datafield:haftalikpuan]" },
        { Id: 479475, PropertyName: "value", Value: "/" },
        { Id: 47225, PropertyName: "value", Value: "[datafield:aylikpuan]" },
        { Id: 118158, PropertyName: "value", Value: "Bugün Tamamlanması Gereken" },
        { Id: 777512, PropertyName: "value", Value: "Bugün Tamamlanmış" },
        { Id: 20949, PropertyName: "value", Value: "Haftalık Ortalama" },
        { Id: 668445, PropertyName: "value", Value: "Aylık Ortalama" },
        { Id: 564331, PropertyName: "value", Value: "Şirket Sayısı" },
        { Id: 471773, PropertyName: "value", Value: "Şube Sayısı" },
        { Id: 325187, PropertyName: "value", Value: "Kullanıcı Sayısı" },
        { Id: 424525, PropertyName: "value", Value: "Denetçi Sayısı" },
        { Id: 579053, PropertyName: "value", Value: "Total Şube" },
        { Id: 154105, PropertyName: "value", Value: "Denetlenmemiş Checklist" },
        { Id: 910844, PropertyName: "value", Value: "Denetlenmemiş Soru Sayısı" },
        { Id: 738574, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı..." },
        { Id: 457819, PropertyName: "title", Value: "Şube Adı" },
        { Id: 674758, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 403700, PropertyName: "title", Value: "Tamamlanan Soru Sayısı" },
        { Id: 933089, PropertyName: "value", Value: "[datafield:tamamlanansoru]" },
        { Id: 753894, PropertyName: "title", Value: "Tamamlanmayan Soru Sayısı" },
        { Id: 647437, PropertyName: "value", Value: "[datafield:tamamlanmayansoru]" },
        { Id: 974319, PropertyName: "value", Value: "Bölge bazında zamanında tamamlama" },
        { Id: 203659, PropertyName: "value", Value: "Bölge bazında tamamlama" },
        { Id: 153397, PropertyName: "value", Value: "Bölge bazında tamamlama oranı" },
        { Id: 599170, PropertyName: "value", Value: "Bölge bazında geç kalma oranı" },
        { Id: 237856, PropertyName: "value", Value: "Bölge / Aylık Ortalama" },
        { Id: 597124, PropertyName: "value", Value: "bölge / Haftalık Ortalama" },
        { Id: 855062, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı..." },
        { Id: 200288, PropertyName: "title", Value: "Bölge Adı" },
        { Id: 842257, PropertyName: "value", Value: "[datafield:bolgeadi]" },
        { Id: 853921, PropertyName: "title", Value: "Bölge Skoru" },
        { Id: 973445, PropertyName: "value", Value: "[datafield:bolgeskoru]" },
        { Id: 881650, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı..." },
        { Id: 907812, PropertyName: "title", Value: "Şube Adı" },
        { Id: 790345, PropertyName: "value", Value: "[datafield:subeadi]" },
        { Id: 655987, PropertyName: "title", Value: "Şube Skoru" },
        { Id: 441057, PropertyName: "value", Value: "[datafield:subeskoru]" },
        { Id: 222643, PropertyName: "value", Value: "Şube bazında zamanında tamamlama" },
        { Id: 833963, PropertyName: "value", Value: "Şube bazında tamamlama" },
        { Id: 814755, PropertyName: "value", Value: "Şube bazında tamamlama oranı" },
        { Id: 462555, PropertyName: "value", Value: "Şube bazında geç kalma oranı" },
        { Id: 882182, PropertyName: "value", Value: "Şube / Aylık Ortalama" },
        { Id: 927237, PropertyName: "value", Value: "Şube / Haftalık Ortalama" },
        { Id: 479985, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı..." },
        { Id: 953665, PropertyName: "title", Value: "Şube Adı" },
        { Id: 299592, PropertyName: "value", Value: "[datafield:subeadi]" },
        { Id: 704561, PropertyName: "title", Value: "Şube Skoru" },
        { Id: 525696, PropertyName: "value", Value: "[datafield:subeskoru]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepage_793690_value_kuikaGalleryViewRef = React.createRef();
    this.homepage_738574_value_kuikaTableRef = React.createRef();
    this.homepage_855062_value_kuikaTableRef = React.createRef();
    this.homepage_881650_value_kuikaTableRef = React.createRef();
    this.homepage_479985_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      SubeAdminStatistics: [],
      SubeAdminKategoriler: [],
      SubeUserStatistics: [],
      AvaneAdmin: [],
      DenetciStatistics: [],
      DenetciSubeler: [],
      SirketAdminStatistics: [],
      SirketAdminBolgeler: [],
      SirketAdminSubeler: [],
      BolgeAdminStatistics: [],
      BolgeAdminSubeler: [],
      NAVIGATE: "",
      isComphomepage_478649AuthorizationVisible: "visible",
      isComphomepage_614685AuthorizationVisible: "visible",
      isComphomepage_227012AuthorizationVisible: "visible",
      isComphomepage_55134AuthorizationVisible: "visible",
      isComphomepage_210626AuthorizationVisible: "visible",
      isComphomepage_86436AuthorizationVisible: "visible",
      isComphomepage_57559AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HomePagePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_478649AuthorizationVisible", [
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "SubeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_614685AuthorizationVisible", [
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "Sube"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_227012AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_55134AuthorizationVisible", [
      "26752c40-3965-9e42-b504-79c0a1a7fc26",
      "Denetci"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_210626AuthorizationVisible", [
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_86436AuthorizationVisible", [
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "BolgeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepage_57559AuthorizationVisible", []);

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.homepage_602282_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    stateVars.SubeAdminStatistics = result?.data.subeAdminStatistics;
    formVars.homepage_292749_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.totalItem : null
    );
    formVars.homepage_891590_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukTamamlanmayanIsler : null
    );
    formVars.homepage_205811_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukZamaniGelmisGecmisIsler : null
    );
    formVars.homepage_309671_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukAktifIsler : null
    );
    formVars.homepage_348536_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.haftalikOrtalama : null
    );
    formVars.homepage_166189_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukTamamlanmisIsler : null
    );
    formVars.homepage_54346_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukTimeEndTamamlanmisIsler : null
    );
    formVars.homepage_501464_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0
        ? stateVars.SubeAdminStatistics[0]?.gunlukTimendGerekceliTamamlanmisIsler
        : null
    );
    formVars.homepage_523397_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.gunlukGecTamamlananIsler : null
    );
    formVars.homepage_736486_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeAdminStatistics?.length > 0 ? stateVars.SubeAdminStatistics[0]?.aylikOrtalama : null
    );
    stateVars.SubeAdminKategoriler = result?.data.subeAdminKategoriler;

    stateVars.SubeUserStatistics = result?.data.subeUserStatistics;
    formVars.homepage_62664_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeUserStatistics?.length > 0 ? stateVars.SubeUserStatistics[0]?.totalItem : null
    );
    formVars.homepage_906257_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeUserStatistics?.length > 0 ? stateVars.SubeUserStatistics[0]?.gunlukTamamlanmisIsler : null
    );
    formVars.homepage_583399_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeUserStatistics?.length > 0 ? stateVars.SubeUserStatistics[0]?.haftalikOrtalama : null
    );
    formVars.homepage_714041_value = ReactSystemFunctions.toString(
      this,
      stateVars.SubeUserStatistics?.length > 0 ? stateVars.SubeUserStatistics[0]?.aylikOrtalama : null
    );
    stateVars.AvaneAdmin = result?.data.avaneAdmin;
    formVars.homepage_765915_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneAdmin?.length > 0 ? stateVars.AvaneAdmin[0]?.sirketSayisi : null
    );
    formVars.homepage_801300_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneAdmin?.length > 0 ? stateVars.AvaneAdmin[0]?.subeSayisi : null
    );
    formVars.homepage_184586_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneAdmin?.length > 0 ? stateVars.AvaneAdmin[0]?.kullaniciSayisi : null
    );
    formVars.homepage_960421_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneAdmin?.length > 0 ? stateVars.AvaneAdmin[0]?.denetciSayisi : null
    );
    stateVars.DenetciStatistics = result?.data.denetciStatistics;
    formVars.homepage_235348_value = ReactSystemFunctions.toString(
      this,
      stateVars.DenetciStatistics?.length > 0 ? stateVars.DenetciStatistics[0]?.totalSube : null
    );
    formVars.homepage_500418_value = ReactSystemFunctions.toString(
      this,
      stateVars.DenetciStatistics?.length > 0 ? stateVars.DenetciStatistics[0]?.denetlenmemisChecklist : null
    );
    formVars.homepage_324713_value = ReactSystemFunctions.toString(
      this,
      stateVars.DenetciStatistics?.length > 0 ? stateVars.DenetciStatistics[0]?.denetlenmemisSoruSayisi : null
    );
    stateVars.DenetciSubeler = result?.data.denetciSubeler;

    stateVars.SirketAdminStatistics = result?.data.sirketAdminStatistics;
    formVars.homepage_951068_value = ReactSystemFunctions.toString(
      this,
      stateVars.SirketAdminStatistics?.length > 0 ? stateVars.SirketAdminStatistics[0]?.bolgeAylikOrtalama : null
    );
    formVars.homepage_589694_value = ReactSystemFunctions.toString(
      this,
      stateVars.SirketAdminStatistics?.length > 0 ? stateVars.SirketAdminStatistics[0]?.bolgeHaftalikOrtalama : null
    );
    stateVars.SirketAdminBolgeler = result?.data.sirketAdminBolgeler;

    stateVars.SirketAdminSubeler = result?.data.sirketAdminSubeler;

    stateVars.BolgeAdminStatistics = result?.data.bolgeAdminStatistics;
    formVars.homepage_544718_value = ReactSystemFunctions.toString(
      this,
      stateVars.BolgeAdminStatistics?.length > 0 ? stateVars.BolgeAdminStatistics[0]?.subeAylikOrtalama : null
    );
    formVars.homepage_317446_value = ReactSystemFunctions.toString(
      this,
      stateVars.BolgeAdminStatistics?.length > 0 ? stateVars.BolgeAdminStatistics[0]?.subeHaftalikOrtalama : null
    );
    stateVars.BolgeAdminSubeler = result?.data.bolgeAdminSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.homepage_602282_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    formVars.homepage_292749_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.totalItem : null
    );

    formVars.homepage_891590_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.gunlukTamamlanmayanIsler : null
    );

    formVars.homepage_205811_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0
        ? this.state.SubeAdminStatistics[0]?.gunlukZamaniGelmisGecmisIsler
        : null
    );

    formVars.homepage_309671_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.gunlukAktifIsler : null
    );

    formVars.homepage_348536_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.haftalikOrtalama : null
    );

    formVars.homepage_166189_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.gunlukTamamlanmisIsler : null
    );

    formVars.homepage_54346_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0
        ? this.state.SubeAdminStatistics[0]?.gunlukTimeEndTamamlanmisIsler
        : null
    );

    formVars.homepage_501464_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0
        ? this.state.SubeAdminStatistics[0]?.gunlukTimendGerekceliTamamlanmisIsler
        : null
    );

    formVars.homepage_523397_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.gunlukGecTamamlananIsler : null
    );

    formVars.homepage_736486_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeAdminStatistics?.length > 0 ? this.state.SubeAdminStatistics[0]?.aylikOrtalama : null
    );

    stateVars.dataSource_793690 = this.state.SubeAdminKategoriler;
    formVars.homepage_62664_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeUserStatistics?.length > 0 ? this.state.SubeUserStatistics[0]?.totalItem : null
    );

    formVars.homepage_906257_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeUserStatistics?.length > 0 ? this.state.SubeUserStatistics[0]?.gunlukTamamlanmisIsler : null
    );

    formVars.homepage_583399_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeUserStatistics?.length > 0 ? this.state.SubeUserStatistics[0]?.haftalikOrtalama : null
    );

    formVars.homepage_714041_value = ReactSystemFunctions.toString(
      this,
      this.state.SubeUserStatistics?.length > 0 ? this.state.SubeUserStatistics[0]?.aylikOrtalama : null
    );

    formVars.homepage_765915_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneAdmin?.length > 0 ? this.state.AvaneAdmin[0]?.sirketSayisi : null
    );

    formVars.homepage_801300_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneAdmin?.length > 0 ? this.state.AvaneAdmin[0]?.subeSayisi : null
    );

    formVars.homepage_184586_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneAdmin?.length > 0 ? this.state.AvaneAdmin[0]?.kullaniciSayisi : null
    );

    formVars.homepage_960421_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneAdmin?.length > 0 ? this.state.AvaneAdmin[0]?.denetciSayisi : null
    );

    formVars.homepage_235348_value = ReactSystemFunctions.toString(
      this,
      this.state.DenetciStatistics?.length > 0 ? this.state.DenetciStatistics[0]?.totalSube : null
    );

    formVars.homepage_500418_value = ReactSystemFunctions.toString(
      this,
      this.state.DenetciStatistics?.length > 0 ? this.state.DenetciStatistics[0]?.denetlenmemisChecklist : null
    );

    formVars.homepage_324713_value = ReactSystemFunctions.toString(
      this,
      this.state.DenetciStatistics?.length > 0 ? this.state.DenetciStatistics[0]?.denetlenmemisSoruSayisi : null
    );

    stateVars.dataSource_738574 = this.state.DenetciSubeler;

    stateVars.dataSource_23974 = this.state.SirketAdminBolgeler;

    stateVars.dataSource_851761 = this.state.SirketAdminBolgeler;

    stateVars.dataSource_417986 = this.state.SirketAdminBolgeler;

    stateVars.dataSource_367886 = this.state.SirketAdminBolgeler;
    formVars.homepage_951068_value = ReactSystemFunctions.toString(
      this,
      this.state.SirketAdminStatistics?.length > 0 ? this.state.SirketAdminStatistics[0]?.bolgeAylikOrtalama : null
    );

    formVars.homepage_589694_value = ReactSystemFunctions.toString(
      this,
      this.state.SirketAdminStatistics?.length > 0 ? this.state.SirketAdminStatistics[0]?.bolgeHaftalikOrtalama : null
    );

    stateVars.dataSource_855062 = this.state.SirketAdminBolgeler;

    stateVars.dataSource_881650 = this.state.SirketAdminSubeler;

    stateVars.dataSource_509358 = this.state.BolgeAdminSubeler;

    stateVars.dataSource_714655 = this.state.BolgeAdminSubeler;

    stateVars.dataSource_767852 = this.state.BolgeAdminSubeler;

    stateVars.dataSource_967123 = this.state.BolgeAdminSubeler;
    formVars.homepage_544718_value = ReactSystemFunctions.toString(
      this,
      this.state.BolgeAdminStatistics?.length > 0 ? this.state.BolgeAdminStatistics[0]?.subeAylikOrtalama : null
    );

    formVars.homepage_317446_value = ReactSystemFunctions.toString(
      this,
      this.state.BolgeAdminStatistics?.length > 0 ? this.state.BolgeAdminStatistics[0]?.subeHaftalikOrtalama : null
    );

    stateVars.dataSource_479985 = this.state.BolgeAdminSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePageComponent_556738_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "728677",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [728677] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagePageInit();
    }
  }
}
