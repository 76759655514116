import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDenetim_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDenetim_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  MyContext: any[];
  PeriyodikIslemler: any[];
  ChecklistGunlukSearch: any[];
  ChecklistGunlukCount: any[];
  isComp314392Visible: "visible" | "hidden";
}

export class Denetim_ScreenBase extends React.PureComponent<IDenetim_ScreenProps, any> {
  denetim_659230_value_kuikaDateRef: React.RefObject<any>;
  denetim_399769_value_kuikaTableRef: React.RefObject<any>;
  denetim_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "77461d48-2e27-462f-ac6b-365ad737bda1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Denetim" },
        { Id: 659230, PropertyName: "placeholder", Value: "Tarih seçiniz..." },
        { Id: 416063, PropertyName: "placeholder", Value: "Checklist adı ile arayınız..." },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 792509, PropertyName: "title", Value: "Denetim" },
        { Id: 61913, PropertyName: "value", Value: "[datafield:oran]" },
        { Id: 3607127, PropertyName: "title", Value: "Tarih" },
        { Id: 5976116, PropertyName: "value", Value: "[datafield:atanmatarihi]" },
        { Id: 314392, PropertyName: "title", Value: "Şirket" },
        { Id: 712248, PropertyName: "value", Value: "[datafield:sirketadi]" },
        { Id: 546781, PropertyName: "title", Value: "Şube" },
        { Id: 971300, PropertyName: "value", Value: "[datafield:subeadi]" },
        { Id: 722635, PropertyName: "title", Value: "Checklist Adı" },
        { Id: 85354, PropertyName: "value", Value: "[datafield:tanimi]" },
        { Id: 286703, PropertyName: "title", Value: "Kategori" },
        { Id: 639493, PropertyName: "value", Value: "[datafield:kategori]" },
        { Id: 474004, PropertyName: "title", Value: "Puan" },
        { Id: 227383, PropertyName: "value", Value: "[datafield:gunsonupuani]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.denetim_659230_value_kuikaDateRef = React.createRef();
    this.denetim_399769_value_kuikaTableRef = React.createRef();
    this.denetim_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      MyContext: [],
      PeriyodikIslemler: [],
      ChecklistGunlukSearch: [],
      ChecklistGunlukCount: [],
      isComp314392Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("denetim", "denetim");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DenetimPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("denetim", "denetim");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "BolgeAdmin", "Denetci"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("denetim", "denetim");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DenetimPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DenetimPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      date_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      date_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Denetim/DenetimPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.denetim_459129_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    stateVars.isComp314392Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.roleName : null),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.PeriyodikIslemler = result?.data.periyodikIslemler;
    stateVars.ChecklistGunlukSearch = result?.data.checklistGunlukSearch;

    stateVars.ChecklistGunlukCount = result?.data.checklistGunlukCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.denetim_459129_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    stateVars.dataSource_399769 = this.state.ChecklistGunlukSearch;
    stateVars.isComp314392Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    formVars.denetim_172599_total = ReactSystemFunctions.value(
      this,
      this.state.ChecklistGunlukCount?.length > 0 ? this.state.ChecklistGunlukCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DenetimComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Denetim",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "558556",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_599656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Denetim",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_659230_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Denetim/DenetimComponent_659230_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistGunlukSearch = result?.data.checklistGunlukSearch;

    stateVars.ChecklistGunlukCount = result?.data.checklistGunlukCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimComponent_659230_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimComponent_659230_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.ChecklistGunlukSearch;
    formVars.denetim_172599_total = ReactSystemFunctions.value(
      this,
      this.state.ChecklistGunlukCount?.length > 0 ? this.state.ChecklistGunlukCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Denetim/DenetimComponent_416063_onPressEnter", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistGunlukSearch = result?.data.checklistGunlukSearch;

    stateVars.ChecklistGunlukCount = result?.data.checklistGunlukCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimComponent_416063_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.ChecklistGunlukSearch;
    formVars.denetim_172599_total = ReactSystemFunctions.value(
      this,
      this.state.ChecklistGunlukCount?.length > 0 ? this.state.ChecklistGunlukCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_484095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Denetim/DenetimComponent_484095_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistGunlukSearch = result?.data.checklistGunlukSearch;

    stateVars.ChecklistGunlukCount = result?.data.checklistGunlukCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimComponent_484095_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimComponent_484095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.ChecklistGunlukSearch;
    formVars.denetim_172599_total = ReactSystemFunctions.value(
      this,
      this.state.ChecklistGunlukCount?.length > 0 ? this.state.ChecklistGunlukCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_652267_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DenetimForm",
      "ChecklistGunlukId",
      ReactSystemFunctions.value(this, "denetim_399769_value", "gunlukId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Denetim",
      "DenetimForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "596538",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_416063_value", "value", "", "", "")),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "denetim_659230_value", "value", "", "", "")),
        "Date"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetim_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Denetim/DenetimComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistGunlukSearch = result?.data.checklistGunlukSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.ChecklistGunlukSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [558556, , 596538] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DenetimPageInit();
    }
  }
}
