import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IForgotPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IForgotPassword_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ForgotPassword: string;
  goToVerificationCodeActivity: any;
  NAVIGATE: any;
}

export class ForgotPassword_ScreenBase extends React.PureComponent<IForgotPassword_ScreenProps, any> {
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "1a9baa74-72dd-4bae-98b0-d4df9cc9e334",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 574431, PropertyName: "value", Value: "Şifrenizi mi unuttunuz ?" },
        { Id: 900580, PropertyName: "value", Value: "Lütfen eposta adresinizi yazınız." },
        { Id: 405510, PropertyName: "placeholder", Value: "eposta@alanadı.com" },
        { Id: 611276, PropertyName: "label", Value: "Gönder" },
        { Id: 520648, PropertyName: "value", Value: "Giriş Yap" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ForgotPassword: "",
      goToVerificationCodeActivity: "",
      NAVIGATE: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword");
      return;
    }

    if (true) {
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("611276");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "forgotpassword");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ForgotPasswordComponent_611276_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "forgotpassword_405510_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "forgotpassword_405510_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ForgotPassword = await ReactSystemFunctions.forgotPassword(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")
      ),
      null
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToVerificationCodeActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ForgotPasswordComponent_520648_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ForgotPassword",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
