import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITodayCevap_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITodayCevap_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  ASoruSelectById: any[];
  CL_GunlukCevapById: any[];
  sbGerekceList: any[];
  ChangeEnabledOf: any;
  UploadFile: any;
  SetValueOf: string;
  PhotoTake: string;
  ToggleVisibilityOf: string;
  GunlukCevapOk: any[];
  GunSonuGuncelle: any[];
  GunlukCevapNOK: number;
  isComp913300Visible: "visible" | "hidden";
  isComp554489Visible: "visible" | "hidden";
  isComp123914Visible: "visible" | "hidden";
  isComp841691Visible: "visible" | "hidden";
  isComp24952Visible: "visible" | "hidden";
  isComp444676Visible: "visible" | "hidden";
  isComp132771Visible: "visible" | "hidden";
  isComp900992Visible: "visible" | "hidden";
  isComp704458Visible: "visible" | "hidden";
  isComp102807Enabled: "enabled" | "disabled";
}

export class TodayCevap_ScreenBase extends React.PureComponent<ITodayCevap_ScreenProps, any> {
  todaycevap_985509_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "dbd82bdb-0f8a-47a6-b1ba-a2718f3fc9ec",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Cevap" },
        { Id: 194003, PropertyName: "value", Value: "Soru Metni" },
        { Id: 437320, PropertyName: "value", Value: "Açıklama" },
        { Id: 893700, PropertyName: "placeholder", Value: "AÇIKLAMA" },
        { Id: 830430, PropertyName: "value", Value: "Belge Yükleme" },
        { Id: 625662, PropertyName: "label", Value: "Belge Ekle" },
        { Id: 102807, PropertyName: "placeholder", Value: "Belge yok..." },
        { Id: 132771, PropertyName: "placeholder", Value: "Data" },
        { Id: 370569, PropertyName: "value", Value: "İmza" },
        { Id: 979895, PropertyName: "value", Value: "Resim" },
        { Id: 155326, PropertyName: "value", Value: "Gerekçe" },
        { Id: 985509, PropertyName: "placeholder", Value: "Gerekçe Seçiniz..!" },
        { Id: 142841, PropertyName: "value", Value: "Gerekçe (Diğer)" },
        { Id: 256785, PropertyName: "placeholder", Value: "METİN" },
        { Id: 433959, PropertyName: "label", Value: "Yapıl(a)madı" },
        { Id: 9544, PropertyName: "label", Value: "Tamamlandı" },
        { Id: 240928, PropertyName: "label", Value: "GEREKÇE KAYDET" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.todaycevap_985509_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ASoruSelectById: [],
      CL_GunlukCevapById: [],
      sbGerekceList: [],
      ChangeEnabledOf: "",
      UploadFile: "",
      SetValueOf: "",
      PhotoTake: "",
      ToggleVisibilityOf: "",
      GunlukCevapOk: [],
      GunSonuGuncelle: [],
      GunlukCevapNOK: 0,
      isComp913300Visible: "hidden",
      isComp554489Visible: "visible",
      isComp123914Visible: "hidden",
      isComp841691Visible: "visible",
      isComp24952Visible: "hidden",
      isComp444676Visible: "hidden",
      isComp132771Visible: "hidden",
      isComp900992Visible: "hidden",
      isComp704458Visible: "hidden",
      isComp102807Enabled: "enabled"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("todaycevap", "todaycevap");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TodayCevapPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("todaycevap", "todaycevap");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["SubeAdmin", "Sube"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("todaycevap", "todaycevap");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TodayCevapPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TodayCevapPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      soruId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SoruId ?? this.props.screenInputs.soruid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ASoruSelectById = result?.data.aSoruSelectById;
    formVars.todaycevap_728694_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.metin : null
    );
    stateVars.isComp24952Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444676Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900992Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CL_GunlukCevapById = result?.data.cL_GunlukCevapById;
    stateVars.sbGerekceList = result?.data.sbGerekceList;

    formVars.todaycevap_985509_options = stateVars.sbGerekceList;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayCevapPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp102807Enabled", "disabled");
    formVars.todaycevap_728694_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.metin : null
    );

    stateVars.isComp24952Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444676Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900992Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_985509 = this.state.sbGerekceList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TodayCevapComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayCevapComponent_625662_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "todaycevap_102807_value",
      ReactSystemFunctions.toString(this, stateVars.UploadFile?.name),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "todaycevap_132771_value",
      ReactSystemFunctions.toString(this, stateVars.UploadFile?.data),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayCevapComponent_762488_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoTake = await ReactSystemFunctions.takePhotoKuikaFunction(this, false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "todaycevap_762488_value",
      ReactSystemFunctions.value(this, stateVars.PhotoTake),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayCevapComponent_433959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp913300Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp554489Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp123914Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp841691Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "todaycevap_893700_value", "NOK", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayCevapComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      filename_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      SonlandiranKullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      TamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "EvetHayir"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukCevapOk = result?.data.gunlukCevapOk;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick1_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_762488_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "todaycevap_762488_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      filename_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      SonlandiranKullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      TamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_762488_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukCevapOk = result?.data.gunlukCevapOk;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "todaycevap_893700_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "todaycevap_893700_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      filename_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      SonlandiranKullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      TamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_893700_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukCevapOk = result?.data.gunlukCevapOk;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "todaycevap_772634_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "todaycevap_772634_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      filename_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      SonlandiranKullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      TamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_772634_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukCevapOk = result?.data.gunlukCevapOk;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "todaycevap_102807_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "todaycevap_102807_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      filename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_102807_value", "value", "", "", "")
        ),
        "string"
      ),
      SonlandiranKullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      TamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      Cevap_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_132771_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick4_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukCevapOk = result?.data.gunlukCevapOk;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayCevapComponent_9544_onClick5_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      gunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.CL_GunlukCevapById?.length > 0 ? this.state.CL_GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_9544_onClick5_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunSonuGuncelle = result?.data.gunSonuGuncelle;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayCevapComponent_9544_onClick6_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_9544_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayCevapComponent_240928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "todaycevap_256785_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "todaycevap_256785_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "todaycevap_985509_value", "value", "sbGerekceList", "id", "gerekceTanim")
        ),
        "DİĞER"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      tamamlanamamaNedeni_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_256785_value", "value", "", "", "")
        ),
        "string"
      ),
      kullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      GerekceID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_985509_value", "value", "sbGerekceList", "id", "id")
        ),
        "Guid"
      ),
      GerekceYuzdeAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "todaycevap_985509_value", "value", "sbGerekceList", "id", "yuzdeAgirligi")
        ),
        "number"
      ),
      cevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CevapId ?? this.props.screenInputs.cevapid,
        "Guid"
      ),
      gunlukId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.CL_GunlukCevapById?.length > 0 ? this.state.CL_GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TodayCevap/TodayCevapComponent_240928_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukCevapNOK = result?.data.gunlukCevapNOK;
    stateVars.GunSonuGuncelle = result?.data.gunSonuGuncelle;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayCevapComponent_240928_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayCevapComponent_240928_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
