import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IClList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IClList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  MyContext: any[];
  ASirketAll: any[];
  CL_ChecklistSearch: any[];
  CL_ChecklistCount: any[];
  TriggerEvent: string;
  SetValueOf: string;
  CL_ChecklistDeleteById: any[];
  isComp814413Visible: "visible" | "hidden";
  isComp3607127Visible: "visible" | "hidden";
  isCompcllist_77473AuthorizationVisible: "visible" | "hidden";
}

export class ClList_ScreenBase extends React.PureComponent<IClList_ScreenProps, any> {
  cllist_77473_value_kuikaSelectBoxRef: React.RefObject<any>;
  cllist_399769_value_kuikaTableRef: React.RefObject<any>;
  cllist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "f21a8e34-98a8-4cb1-95f9-7dd47f5733e9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Checklist Listesi" },
        { Id: 77473, PropertyName: "placeholder", Value: "Şirket Seçiniz..." },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 330679, PropertyName: "value", Value: "Arşiv Göster" },
        { Id: 814413, PropertyName: "label", Value: "YENİ TASLAK" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 3607127, PropertyName: "title", Value: "Şirket" },
        { Id: 5976116, PropertyName: "value", Value: "[datafield:sirketadi]" },
        { Id: 985670, PropertyName: "title", Value: "Kategori" },
        { Id: 671342, PropertyName: "value", Value: "[datafield:kategoritanim]" },
        { Id: 917827, PropertyName: "title", Value: "Checklist Adı" },
        { Id: 887183, PropertyName: "value", Value: "[datafield:tanimi]" },
        { Id: 420064, PropertyName: "title", Value: "Versiyon" },
        { Id: 970802, PropertyName: "value", Value: "v" },
        { Id: 121018, PropertyName: "value", Value: "[datafield:versiyon]" },
        { Id: 978825, PropertyName: "title", Value: "Durum" },
        { Id: 657009, PropertyName: "value", Value: "[datafield:durumtanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.cllist_77473_value_kuikaSelectBoxRef = React.createRef();
    this.cllist_399769_value_kuikaTableRef = React.createRef();
    this.cllist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      MyContext: [],
      ASirketAll: [],
      CL_ChecklistSearch: [],
      CL_ChecklistCount: [],
      TriggerEvent: "",
      SetValueOf: "",
      CL_ChecklistDeleteById: [],
      isComp814413Visible: "hidden",
      isComp3607127Visible: "hidden",
      isCompcllist_77473AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("cllist", "cllist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ClListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcllist_77473AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("cllist", "cllist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("cllist", "cllist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ClListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      cllist_77473_value: this.state.MyContext?.at?.(0)?.sirketId ?? undefined,
      cllist_345696_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ClListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      pageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_416063_value", "value", "", "", "")),
        "string"
      ),
      tumKayit_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_345696_value", "value", "", "", "")),
        "boolean"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      sirketId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_416063_value", "value", "", "", "")),
        "string"
      ),
      tumKayit_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_345696_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClList/ClListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.cllist_749852_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    formVars.cllist_77473_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.cllist_77473_options = stateVars.ASirketAll;
    stateVars.isComp3607127Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.roleName : null),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.cllist_77473_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.cllist_77473_options = stateVars.ASirketAll;
    stateVars.CL_ChecklistSearch = result?.data.cL_ChecklistSearch;

    stateVars.CL_ChecklistCount = result?.data.cL_ChecklistCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.cllist_749852_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    formVars.cllist_77473_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null
    );

    stateVars.dataSource_77473 = this.state.ASirketAll;
    stateVars.isComp814413Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_399769 = this.state.CL_ChecklistSearch;
    stateVars.isComp3607127Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    formVars.cllist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_ChecklistCount?.length > 0 ? this.state.CL_ChecklistCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ClListComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "558556",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_953393_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClList",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_77473_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.ClListComponent_484095_onClick()) return true;

    stateVars.isComp814413Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.ClListComponent_484095_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_345696_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.ClListComponent_484095_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_484095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_416063_value", "value", "", "", "")),
        "string"
      ),
      tumKayit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_345696_value", "value", "", "", "")),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_416063_value", "value", "", "", "")),
        "string"
      ),
      tumKayit_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_345696_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClList/ClListComponent_484095_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_ChecklistSearch = result?.data.cL_ChecklistSearch;

    stateVars.CL_ChecklistCount = result?.data.cL_ChecklistCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClListComponent_484095_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClListComponent_484095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "cllist_172599_value", 1, null);

    stateVars.dataSource_399769 = this.state.CL_ChecklistSearch;
    formVars.cllist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_ChecklistCount?.length > 0 ? this.state.CL_ChecklistCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("ClForm", "ChecklistId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "ClForm",
      "SirketId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.ClListComponent_814413_onClick1_,
      "ClList",
      "ClForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "697242",
      null,
      "right",
      null,
      "70vw",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  ClListComponent_814413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ClListComponent_484095_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ClForm",
      "ChecklistId",
      ReactSystemFunctions.value(this, "cllist_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "ClForm",
      "SirketId",
      ReactSystemFunctions.value(this, "cllist_399769_value", "sirketId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClList",
      "ClForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "563929",
      null,
      "right",
      null,
      "70vw",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_305687_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("ClReadOnly", "IsNew", false);
    KuikaAppManager.addToPageInputVariables(
      "ClReadOnly",
      "ChecklistId",
      ReactSystemFunctions.value(this, "cllist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClList",
      "ClReadOnly",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "235782",
      null,
      "right",
      null,
      "70vw",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "cllist_399769_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ClList/ClListComponent_505853_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CL_ChecklistDeleteById = result?.data.cL_ChecklistDeleteById;
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.ClListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ClListComponent_484095_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_77473_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_416063_value", "value", "", "", "")),
        "string"
      ),
      tumKayit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "cllist_345696_value", "value", "", "", "")),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "cllist_172599_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClList/ClListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_ChecklistSearch = result?.data.cL_ChecklistSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_ChecklistSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [558556, , 563929, 235782] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ClListPageInit();
    }
    if (diId == 697242) {
      isErrorOccurred = await this.ClListComponent_814413_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
