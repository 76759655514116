import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IToday_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IToday_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  GunlukChecklist: any[];
  GunlukChecklistSorular: any[];
  GunlukChecklistSorularCount: any[];
  NAVIGATE: any;
  SetValueOf: string;
  IfThenElse: boolean;
  isComptoday_290923AuthorizationVisible: "visible" | "hidden";
  isComptoday_567590AuthorizationVisible: "visible" | "hidden";
}

export class Today_ScreenBase extends React.PureComponent<IToday_ScreenProps, any> {
  today_595596_value_kuikaSelectBoxRef: React.RefObject<any>;
  today_268011_value_kuikaTableRef: React.RefObject<any>;
  today_687409_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "f167af23-9312-4665-bccf-62dafea3c40d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 964623, PropertyName: "value", Value: "Bugün" },
        { Id: 810087, PropertyName: "value", Value: "CheckList" },
        { Id: 351702, PropertyName: "value", Value: "CheckList" },
        { Id: 595596, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 771130, PropertyName: "value", Value: "Kapatılanları da Göster" },
        { Id: 268011, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 111803, PropertyName: "title", Value: "Soru Metni" },
        { Id: 588211, PropertyName: "value", Value: "[datafield:sorumetni]" },
        { Id: 800363, PropertyName: "title", Value: "Cevap" },
        { Id: 439434, PropertyName: "value", Value: "[datafield:cevap]" },
        { Id: 371118, PropertyName: "value", Value: "[datafield:tamamlanamamanedeni]" },
        { Id: 713159, PropertyName: "title", Value: "Başlangıç" },
        { Id: 498380, PropertyName: "value", Value: "[datafield:terminbaslangic]" },
        { Id: 597394, PropertyName: "title", Value: "Bitiş" },
        { Id: 901743, PropertyName: "value", Value: "[datafield:terminbitis]" },
        { Id: 284141, PropertyName: "title", Value: "Yüzde" },
        { Id: 522087, PropertyName: "value", Value: "[datafield:yuzdeagirligi]" },
        { Id: 553320, PropertyName: "title", Value: "Tolerans" },
        { Id: 821998, PropertyName: "value", Value: "[datafield:toleranssuresidakika]" },
        { Id: 971880, PropertyName: "value", Value: "-" },
        { Id: 994466, PropertyName: "value", Value: "[datafield:toleranspuanyuzdesi]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.today_595596_value_kuikaSelectBoxRef = React.createRef();
    this.today_268011_value_kuikaTableRef = React.createRef();
    this.today_687409_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      GunlukChecklist: [],
      GunlukChecklistSorular: [],
      GunlukChecklistSorularCount: [],
      NAVIGATE: "",
      SetValueOf: "",
      IfThenElse: false,
      isComptoday_290923AuthorizationVisible: "visible",
      isComptoday_567590AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("today", "today");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TodayPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComptoday_290923AuthorizationVisible", [
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "Sube"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComptoday_567590AuthorizationVisible", [
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "SubeAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("today", "today");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["SubeAdmin", "Sube"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("today", "today");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TodayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      today_908965_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TodayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.today_771705_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    stateVars.GunlukChecklist = result?.data.gunlukChecklist;

    formVars.today_595596_options = stateVars.GunlukChecklist;
    formVars.today_563030_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukChecklist?.length > 0 ? stateVars.GunlukChecklist[0]?.kategoriAdi : null
    );
    formVars.today_863172_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukChecklist?.length > 0 ? stateVars.GunlukChecklist[0]?.checklistAdi : null
    );
    formVars.today_535067_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukChecklist?.length > 0 ? stateVars.GunlukChecklist[0]?.kategoriAdi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Today/TodayPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayPageInit2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Today/TodayPageInit2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukChecklistSorularCount = result?.data.gunlukChecklistSorularCount;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayPageInit3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.today_771705_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    formVars.today_563030_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.kategoriAdi : null
    );

    formVars.today_863172_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.checklistAdi : null
    );

    formVars.today_535067_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.kategoriAdi : null
    );

    stateVars.dataSource_595596 = this.state.GunlukChecklist;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayPageInit4_();
    });

    return isErrorOccurred;
  };
  TodayPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayPageInit5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayPageInit5_();
      });
    }

    return isErrorOccurred;
  };
  TodayPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      stateVars.dataSource_268011 = this.state.GunlukChecklistSorular;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayPageInit6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayPageInit6_();
      });
    }

    return isErrorOccurred;
  };
  TodayPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      formVars.today_687409_total = ReactSystemFunctions.value(
        this,
        this.state.GunlukChecklistSorularCount?.length > 0
          ? this.state.GunlukChecklistSorularCount[0]?.sorularCount
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TodayComponent_556738_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Today",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "728677",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayComponent_595596_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "today_687409_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayComponent_595596_onChange1_();
    });

    return isErrorOccurred;
  };
  TodayComponent_595596_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      ),
      clGunlukId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        "Guid"
      ),
      KapatilanlaridaGoster_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_595596_onChange1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

    stateVars.GunlukChecklistSorularCount = result?.data.gunlukChecklistSorularCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayComponent_595596_onChange2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayComponent_595596_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_268011 = this.state.GunlukChecklistSorular;
    formVars.today_687409_total = ReactSystemFunctions.value(
      this,
      this.state.GunlukChecklistSorularCount?.length > 0
        ? this.state.GunlukChecklistSorularCount[0]?.sorularCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayComponent_908965_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "today_687409_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TodayComponent_908965_onChange1_();
    });

    return isErrorOccurred;
  };
  TodayComponent_908965_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_908965_onChange1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayComponent_908965_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_908965_onChange2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GunlukChecklistSorularCount = result?.data.gunlukChecklistSorularCount;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TodayComponent_908965_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange4_();
      });
    }

    return isErrorOccurred;
  };
  TodayComponent_908965_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      stateVars.dataSource_268011 = this.state.GunlukChecklistSorular;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.TodayComponent_908965_onChange5_();
      });
    }

    return isErrorOccurred;
  };
  TodayComponent_908965_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "Sube"
      )
    ) {
      formVars.today_687409_total = ReactSystemFunctions.value(
        this,
        this.state.GunlukChecklistSorularCount?.length > 0
          ? this.state.GunlukChecklistSorularCount[0]?.sorularCount
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TodayComponent_610323_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TodayCevap",
      "SoruId",
      ReactSystemFunctions.value(this, "today_268011_value", "soruId")
    );
    KuikaAppManager.addToPageInputVariables(
      "TodayCevap",
      "CevapId",
      ReactSystemFunctions.value(this, "today_268011_value", "cevapId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.TodayComponent_610323_onClick1_,
      "Today",
      "TodayCevap",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "559328",
      null,
      "right",
      null,
      "380px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  TodayComponent_610323_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_610323_onClick_if1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TodayComponent_610323_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_610323_onClick_else1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TodayComponent_610323_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        null
      )
    ) {
      isErrorOccurred = await this.TodayComponent_610323_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.TodayComponent_610323_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TodayComponent_687409_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_687409_onChange_if0", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TodayComponent_687409_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      clGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukChecklist?.length > 0 ? this.state.GunlukChecklist[0]?.clGunlukId : null
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_687409_value", "current", "", "", "")),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "today_687409_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "today_908965_value", "value", "", "", "")),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Today/TodayComponent_687409_onChange_else0", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GunlukChecklistSorular = result?.data.gunlukChecklistSorular;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TodayComponent_687409_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "today_595596_value",
            "value",
            "GunlukChecklist",
            "checklistId",
            "clGunlukId"
          )
        ),
        null
      )
    ) {
      isErrorOccurred = await this.TodayComponent_687409_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.TodayComponent_687409_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [728677] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TodayPageInit();
    }
    if (diId == 559328) {
      isErrorOccurred = await this.TodayComponent_610323_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
