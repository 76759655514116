import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IGerekceList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IGerekceList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  MyContext: any[];
  AGerekceSearch: any[];
  AGerekceSearchCount: any[];
  AGerekceDeleteById: number;
}

export class GerekceList_ScreenBase extends React.PureComponent<IGerekceList_ScreenProps, any> {
  gerekcelist_399769_value_kuikaTableRef: React.RefObject<any>;
  gerekcelist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "8f3eadd9-d624-4889-a9bf-537c37903241",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Gerekçe Listesi" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ GEREKÇE" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 2360440, PropertyName: "title", Value: "Gerekçe Tanımı" },
        { Id: 7963713, PropertyName: "value", Value: "[datafield:gerekcetanim]" },
        { Id: 615920, PropertyName: "title", Value: "Ağırlık Yüzdesi" },
        { Id: 90367, PropertyName: "value", Value: "[datafield:yuzdeagirligi]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.gerekcelist_399769_value_kuikaTableRef = React.createRef();
    this.gerekcelist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      MyContext: [],
      AGerekceSearch: [],
      AGerekceSearchCount: [],
      AGerekceDeleteById: 0
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("gerekcelist", "gerekcelist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.GerekceListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("gerekcelist", "gerekcelist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("gerekcelist", "gerekcelist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.GerekceListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  GerekceListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "GerekceList/GerekceListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.gerekcelist_994043_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    stateVars.AGerekceSearch = result?.data.aGerekceSearch;

    stateVars.AGerekceSearchCount = result?.data.aGerekceSearchCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.GerekceListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.gerekcelist_994043_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    stateVars.dataSource_399769 = this.state.AGerekceSearch;
    formVars.gerekcelist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.AGerekceSearchCount?.length > 0 ? this.state.AGerekceSearchCount[0]?.gerekceCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  GerekceListComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GerekceList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "726899",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_611_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GerekceList",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "GerekceList/GerekceListComponent_416063_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AGerekceSearch = result?.data.aGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.GerekceListComponent_416063_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.AGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "GerekceList/GerekceListComponent_416063_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AGerekceSearch = result?.data.aGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.GerekceListComponent_416063_onBlur1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.AGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_484095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "GerekceList/GerekceListComponent_484095_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AGerekceSearch = result?.data.aGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.GerekceListComponent_484095_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListComponent_484095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.AGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("GerekceForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GerekceList",
      "GerekceForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "697242",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "GerekceForm",
      "Id",
      ReactSystemFunctions.value(this, "gerekcelist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GerekceList",
      "GerekceForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "gerekcelist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "67c1740c_b97d_e1a9_02df_681a64cb5f6f_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "GerekceList/GerekceListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.AGerekceDeleteById = result?.data.aGerekceDeleteById;
        stateVars.AGerekceSearch = result?.data.aGerekceSearch;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.GerekceListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.AGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  GerekceListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "gerekcelist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "GerekceList/GerekceListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AGerekceSearch = result?.data.aGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.GerekceListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  GerekceListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.AGerekceSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [726899, , 697242, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.GerekceListPageInit();
    }
  }
}
