import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  isCompleftmenu_101166AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_729436AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_236095AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_135788AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_859803AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_224835AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_390077AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_67607AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_618921AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_205034AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_150799AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_756116AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_583913AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6d276896-a955-45f7-8a56-9db9574ab9cd",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 501123, PropertyName: "value", Value: "AVANE CLOUD KITCHENS" },
        { Id: 950642, PropertyName: "value", Value: "Pano" },
        { Id: 913982, PropertyName: "value", Value: "Bugün" },
        { Id: 708399, PropertyName: "value", Value: "Denetim" },
        { Id: 207454, PropertyName: "value", Value: "Bildirim" },
        { Id: 27009, PropertyName: "value", Value: "Şirketler" },
        { Id: 281917, PropertyName: "value", Value: "Bölgeler" },
        { Id: 96375, PropertyName: "value", Value: "Şubeler" },
        { Id: 375132, PropertyName: "value", Value: "Kategoriler" },
        { Id: 52193, PropertyName: "value", Value: "Kullanıcılar" },
        { Id: 692369, PropertyName: "value", Value: "Sorular" },
        { Id: 81278, PropertyName: "value", Value: "Gerekçe" },
        { Id: 106615, PropertyName: "value", Value: "Checklist'ler" },
        { Id: 188381, PropertyName: "value", Value: "Çıkış Yap" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      isCompleftmenu_101166AuthorizationVisible: "visible",
      isCompleftmenu_729436AuthorizationVisible: "visible",
      isCompleftmenu_236095AuthorizationVisible: "visible",
      isCompleftmenu_135788AuthorizationVisible: "visible",
      isCompleftmenu_859803AuthorizationVisible: "visible",
      isCompleftmenu_224835AuthorizationVisible: "visible",
      isCompleftmenu_390077AuthorizationVisible: "visible",
      isCompleftmenu_67607AuthorizationVisible: "visible",
      isCompleftmenu_618921AuthorizationVisible: "visible",
      isCompleftmenu_205034AuthorizationVisible: "visible",
      isCompleftmenu_150799AuthorizationVisible: "visible",
      isCompleftmenu_756116AuthorizationVisible: "visible",
      isCompleftmenu_583913AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
      return;
    }

    if (true) {
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_101166AuthorizationVisible", [
      "d8b59878-3f20-ed40-8291-3f85029a6bf0",
      "f1b2ed4e-5bc6-0b73-66ad-03207400a89f",
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "SubeAdmin",
      "Sube"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_729436AuthorizationVisible", [
      "26752c40-3965-9e42-b504-79c0a1a7fc26",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin",
      "Denetci"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_236095AuthorizationVisible", [
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "AvaneAdmin",
      "SirketAdmin",
      "SubeAdmin",
      "BolgeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_135788AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "SubeAdmin",
      "BolgeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_859803AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_224835AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_390077AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_67607AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_618921AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "AvaneAdmin",
      "SirketAdmin",
      "SubeAdmin",
      "BolgeAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_205034AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_150799AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_756116AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_583913AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuComponent_620279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_822360_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_101166_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Today",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_729436_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Denetim",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_236095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "NotificationList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_859803_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SirketList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_224835_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BolgeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_390077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SubeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_67607_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "CategoryList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_618921_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "KullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_150799_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SoruList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_756116_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "GerekceList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_583913_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ClList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_20424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, , , , , , , , , , ,] as number[];
  }
}
