import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISubeForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISubeForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  ASubeSelectById: any[];
  ASirketAll: any[];
  BolgeBySirketId: any[];
  SetFocusTo: string;
  SetValueOf: string;
  ASubeSave: number;
  isCompsubeform_3576449AuthorizationVisible: "visible" | "hidden";
  isCompsubeform_8177745AuthorizationVisible: "visible" | "hidden";
}

export class SubeForm_ScreenBase extends React.PureComponent<ISubeForm_ScreenProps, any> {
  subeform_798455_value_kuikaSelectBoxRef: React.RefObject<any>;
  subeform_585515_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6443487a-046c-46ff-a5fc-95586e82ee15",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Şube Tanımı" },
        { Id: 7117054, PropertyName: "value", Value: "Şirket" },
        { Id: 798455, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 5323147, PropertyName: "value", Value: "Bölgesi" },
        { Id: 585515, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 328840, PropertyName: "value", Value: "Şube Adı" },
        { Id: 13703830, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.subeform_798455_value_kuikaSelectBoxRef = React.createRef();
    this.subeform_585515_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ASubeSelectById: [],
      ASirketAll: [],
      BolgeBySirketId: [],
      SetFocusTo: "",
      SetValueOf: "",
      ASubeSave: 0,
      isCompsubeform_3576449AuthorizationVisible: "visible",
      isCompsubeform_8177745AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("subeform", "subeform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SubeFormPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsubeform_3576449AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsubeform_8177745AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("subeform", "subeform");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "BolgeAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("subeform", "subeform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SubeFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      subeform_798455_value: this.state.ASubeSelectById?.at?.(0)?.sirketId ?? undefined,
      subeform_585515_value: this.state.ASubeSelectById?.at?.(0)?.bolgeId ?? undefined,
      subeform_13703830_value: this.state.ASubeSelectById?.at?.(0)?.tanim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SubeFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SubeForm/SubeFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ASubeSelectById = result?.data.aSubeSelectById;
    formVars.subeform_798455_value =
      stateVars.ASubeSelectById?.length > 0 ? stateVars.ASubeSelectById[0]?.sirketId : null;
    formVars.subeform_798455_options = stateVars.ASirketAll;
    formVars.subeform_585515_value =
      stateVars.ASubeSelectById?.length > 0 ? stateVars.ASubeSelectById[0]?.bolgeId : null;
    formVars.subeform_585515_options = stateVars.BolgeBySirketId;
    formVars.subeform_13703830_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASubeSelectById?.length > 0 ? stateVars.ASubeSelectById[0]?.tanim : null
    );
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.subeform_798455_value =
      stateVars.ASubeSelectById?.length > 0 ? stateVars.ASubeSelectById[0]?.sirketId : null;
    formVars.subeform_798455_options = stateVars.ASirketAll;
    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.subeform_585515_value =
      stateVars.ASubeSelectById?.length > 0 ? stateVars.ASubeSelectById[0]?.bolgeId : null;
    formVars.subeform_585515_options = stateVars.BolgeBySirketId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubeFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubeFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetFocusTo = await ReactSystemFunctions.setFocusTo("798455");
    formVars.subeform_798455_value = ReactSystemFunctions.toString(
      this,
      this.state.ASubeSelectById?.length > 0 ? this.state.ASubeSelectById[0]?.sirketId : null
    );

    stateVars.dataSource_798455 = this.state.ASirketAll;
    formVars.subeform_585515_value = ReactSystemFunctions.toString(
      this,
      this.state.ASubeSelectById?.length > 0 ? this.state.ASubeSelectById[0]?.bolgeId : null
    );

    stateVars.dataSource_585515 = this.state.BolgeBySirketId;
    formVars.subeform_13703830_value = ReactSystemFunctions.toString(
      this,
      this.state.ASubeSelectById?.length > 0 ? this.state.ASubeSelectById[0]?.tanim : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SubeFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubeFormComponent_798455_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subeform_798455_value", "value", "ASirketAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "subeform_798455_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subeform_798455_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SubeForm/SubeFormComponent_798455_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.subeform_585515_options = stateVars.BolgeBySirketId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubeFormComponent_798455_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubeFormComponent_798455_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "subeform_585515_value", null, null, "Id");

    stateVars.dataSource_585515 = this.state.BolgeBySirketId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SubeFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subeform_13703830_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "subeform_13703830_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subeform_798455_value", "value", "ASirketAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "subeform_798455_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subeform_585515_value", "value", "BolgeBySirketId", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "subeform_585515_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "subeform_13703830_value", "value", "", "", "")
        ),
        "string"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subeform_798455_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      BolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "subeform_585515_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SubeForm/SubeFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ASubeSave = result?.data.aSubeSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SubeFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SubeFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
