import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IClSoruEkleme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IClSoruEkleme_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  CL_SoruById: any[];
  ASoruSelectById: any[];
  ChangeVisibilityOf: string;
  CL_SoruEkle: number;
  isComp161195Visible: "visible" | "hidden";
}

export class ClSoruEkleme_ScreenBase extends React.PureComponent<IClSoruEkleme_ScreenProps, any> {
  clsoruekleme_863944_value_kuikaSelectBoxRef: React.RefObject<any>;
  clsoruekleme_98774_value_kuikaSelectBoxRef: React.RefObject<any>;
  clsoruekleme_270986_value_kuikaSelectBoxRef: React.RefObject<any>;
  clsoruekleme_607551_value_kuikaSelectBoxRef: React.RefObject<any>;
  clsoruekleme_429614_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "014f2f49-470e-4a87-9d93-2be0581cfe39",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 862695, PropertyName: "value", Value: "Soru Metni" },
        { Id: 136401, PropertyName: "value", Value: "Pay" },
        { Id: 70693, PropertyName: "value", Value: "Termin Zamanı Başlangıç" },
        { Id: 863944, PropertyName: "placeholder", Value: "Saat" },
        { Id: 98774, PropertyName: "placeholder", Value: "Dakika" },
        { Id: 42469, PropertyName: "value", Value: "Termin Zamanı Bitiş" },
        { Id: 270986, PropertyName: "placeholder", Value: "Saat" },
        { Id: 607551, PropertyName: "placeholder", Value: "Dakika" },
        { Id: 164083, PropertyName: "value", Value: "Tolerans Süresi (dk)" },
        { Id: 429614, PropertyName: "placeholder", Value: "Dakika" },
        { Id: 494018, PropertyName: "value", Value: "Tolerans Puan Yüzdesi" },
        { Id: 902946, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.clsoruekleme_863944_value_kuikaSelectBoxRef = React.createRef();
    this.clsoruekleme_98774_value_kuikaSelectBoxRef = React.createRef();
    this.clsoruekleme_270986_value_kuikaSelectBoxRef = React.createRef();
    this.clsoruekleme_607551_value_kuikaSelectBoxRef = React.createRef();
    this.clsoruekleme_429614_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      CL_SoruById: [],
      ASoruSelectById: [],
      ChangeVisibilityOf: "",
      CL_SoruEkle: 0,
      dataSource_863944: [
        { key: "6", text: "6" },
        { key: "7", text: "7" },
        { key: "8", text: "8" },
        { key: "9", text: "9" },
        { key: "10", text: "10" },
        { key: "11", text: "11" },
        { key: "12", text: "12" },
        { key: "13", text: "13" },
        { key: "14", text: "14" },
        { key: "15", text: "15" },
        { key: "16", text: "16" },
        { key: "17", text: "17" },
        { key: "18", text: "18" },
        { key: "19", text: "19" },
        { key: "20", text: "20" },
        { key: "21", text: "21" },
        { key: "22", text: "22" },
        { key: "23", text: "23" },
        { key: "0", text: "0" },
        { key: "1", text: "1" },
        { key: "2", text: "2" }
      ],
      dataSource_98774: [
        { key: "0", text: "00" },
        { key: "15", text: "15" },
        { key: "30", text: "30" },
        { key: "45", text: "45" }
      ],
      dataSource_270986: [
        { key: "6", text: "6" },
        { key: "7", text: "7" },
        { key: "8", text: "8" },
        { key: "9", text: "9" },
        { key: "10", text: "10" },
        { key: "11", text: "11" },
        { key: "12", text: "12" },
        { key: "13", text: "13" },
        { key: "14", text: "14" },
        { key: "15", text: "15" },
        { key: "16", text: "16" },
        { key: "17", text: "17" },
        { key: "18", text: "18" },
        { key: "19", text: "19" },
        { key: "20", text: "20" },
        { key: "21", text: "21" },
        { key: "22", text: "22" },
        { key: "23", text: "23" },
        { key: "0", text: "0" },
        { key: "1", text: "1" },
        { key: "2", text: "2" }
      ],
      dataSource_607551: [
        { key: "0", text: "00" },
        { key: "15", text: "15" },
        { key: "30", text: "30" },
        { key: "45", text: "45" }
      ],
      dataSource_429614: [
        { key: "0", text: "0" },
        { key: "15", text: "15" },
        { key: "30", text: "30" },
        { key: "60", text: "60" },
        { key: "90", text: "90" },
        { key: "120", text: "120" }
      ],
      isComp161195Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("clsoruekleme", "clsoruekleme");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ClSoruEklemePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("clsoruekleme", "clsoruekleme");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("clsoruekleme", "clsoruekleme");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ClSoruEklemePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      clsoruekleme_652212_value: this.state.CL_SoruById?.at?.(0)?.yuzdeAgirligi ?? undefined,
      clsoruekleme_863944_value: this.state.CL_SoruById?.at?.(0)?.terminBaslangicSaat ?? undefined,
      clsoruekleme_98774_value: this.state.CL_SoruById?.at?.(0)?.terminBaslangicDakika ?? undefined,
      clsoruekleme_270986_value: this.state.CL_SoruById?.at?.(0)?.terminBitisSaat ?? undefined,
      clsoruekleme_607551_value: this.state.CL_SoruById?.at?.(0)?.terminBitisDakika ?? undefined,
      clsoruekleme_429614_value: this.state.CL_SoruById?.at?.(0)?.toleransSuresiDakika ?? undefined,
      clsoruekleme_647009_value: this.state.CL_SoruById?.at?.(0)?.toleransPuanYuzdesi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ClSoruEklemePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistSoruId ?? this.props.screenInputs.checklistsoruid,
        "Guid"
      ),
      soruId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SoruId ?? this.props.screenInputs.soruid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClSoruEkleme/ClSoruEklemePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_SoruById = result?.data.cL_SoruById;
    formVars.clsoruekleme_652212_value = ReactSystemFunctions.value(
      this,
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.yuzdeAgirligi : null
    );
    formVars.clsoruekleme_863944_value =
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.terminBaslangicSaat : null;
    formVars.clsoruekleme_98774_value =
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.terminBaslangicDakika : null;
    formVars.clsoruekleme_270986_value =
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.terminBitisSaat : null;
    formVars.clsoruekleme_607551_value =
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.terminBitisDakika : null;
    formVars.clsoruekleme_429614_value =
      stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.toleransSuresiDakika : null;
    stateVars.isComp161195Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_SoruById?.length > 0 ? stateVars.CL_SoruById[0]?.toleransSuresiDakika : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.ASoruSelectById = result?.data.aSoruSelectById;
    formVars.clsoruekleme_606657_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.metin : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClSoruEklemePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClSoruEklemePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.clsoruekleme_606657_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.metin : null
    );

    formVars.clsoruekleme_652212_value = ReactSystemFunctions.value(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.yuzdeAgirligi : null
    );

    formVars.clsoruekleme_863944_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.terminBaslangicSaat : null
    );

    formVars.clsoruekleme_98774_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.terminBaslangicDakika : null
    );

    formVars.clsoruekleme_270986_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.terminBitisSaat : null
    );

    formVars.clsoruekleme_607551_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.terminBitisDakika : null
    );

    formVars.clsoruekleme_429614_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.toleransSuresiDakika : null
    );

    stateVars.isComp161195Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.toleransSuresiDakika : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.clsoruekleme_647009_value = ReactSystemFunctions.value(
      this,
      this.state.CL_SoruById?.length > 0 ? this.state.CL_SoruById[0]?.toleransPuanYuzdesi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ClSoruEklemeComponent_316556_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClSoruEklemeComponent_429614_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp161195Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClSoruEklemeComponent_429614_onChange1_();
    });

    return isErrorOccurred;
  };
  ClSoruEklemeComponent_429614_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_429614_value", "value", "", "key", "")
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp161195Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ClSoruEklemeComponent_902946_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_98774_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_98774_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_863944_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_863944_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_607551_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_607551_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_270986_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_270986_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_647009_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_647009_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_429614_value", "value", "", "key", "")
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_429614_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_429614_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_652212_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "clsoruekleme_652212_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_652212_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "*",
      formName: "clsoruekleme_652212_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistSoruId ?? this.props.screenInputs.checklistsoruid,
        "Guid"
      ),
      ChecklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      SoruId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SoruId ?? this.props.screenInputs.soruid,
        "Guid"
      ),
      TerminBaslangicDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_98774_value", "value", "", "key", "")
        ),
        "number"
      ),
      TerminBaslangicSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_863944_value", "value", "", "key", "")
        ),
        "number"
      ),
      TerminBitisDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_607551_value", "value", "", "key", "")
        ),
        "number"
      ),
      TerminBitisSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_270986_value", "value", "", "key", "")
        ),
        "number"
      ),
      ToleransPuanYuzdesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_647009_value", "value", "", "", "")
        ),
        "number"
      ),
      ToleransSuresiDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_429614_value", "value", "", "key", "")
        ),
        "number"
      ),
      YuzdeAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clsoruekleme_652212_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClSoruEkleme/ClSoruEklemeComponent_902946_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_SoruEkle = result?.data.cL_SoruEkle;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClSoruEklemeComponent_902946_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClSoruEklemeComponent_902946_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
