import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICategoryList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICategoryList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  MyContext: any[];
  ASirketAll: any[];
  CL_CategorySearch: any[];
  CL_CategoryCount: any[];
  CL_CategoryDeleteById: number;
  isCompcategorylist_673469AuthorizationVisible: "visible" | "hidden";
  isCompcategorylist_1703193AuthorizationVisible: "visible" | "hidden";
}

export class CategoryList_ScreenBase extends React.PureComponent<ICategoryList_ScreenProps, any> {
  categorylist_673469_value_kuikaSelectBoxRef: React.RefObject<any>;
  categorylist_399769_value_kuikaTableRef: React.RefObject<any>;
  categorylist_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e1609c09-c92c-4349-b60a-3b0ee5186723",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Checklist Kategorileri" },
        { Id: 673469, PropertyName: "placeholder", Value: "Şirket Seçiniz..." },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ KATEGORİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 1703193, PropertyName: "title", Value: "Şirket Adı" },
        { Id: 8866779, PropertyName: "value", Value: "[datafield:sirketadi]" },
        { Id: 9997118, PropertyName: "title", Value: "Kategori Tanımı" },
        { Id: 5151866, PropertyName: "value", Value: "[datafield:tanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.categorylist_673469_value_kuikaSelectBoxRef = React.createRef();
    this.categorylist_399769_value_kuikaTableRef = React.createRef();
    this.categorylist_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      MyContext: [],
      ASirketAll: [],
      CL_CategorySearch: [],
      CL_CategoryCount: [],
      CL_CategoryDeleteById: "",
      isCompcategorylist_673469AuthorizationVisible: "visible",
      isCompcategorylist_1703193AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("categorylist", "categorylist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CategoryListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcategorylist_673469AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcategorylist_1703193AuthorizationVisible", []);

    KuikaAppManager.calculateAndSetBodyHeight("categorylist", "categorylist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("categorylist", "categorylist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CategoryListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      categorylist_673469_value: this.state.MyContext?.at?.(0)?.sirketId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CategoryListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CategoryList/CategoryListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.categorylist_41919_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    formVars.categorylist_673469_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.categorylist_673469_options = stateVars.ASirketAll;
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.categorylist_673469_value = stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketId : null;
    formVars.categorylist_673469_options = stateVars.ASirketAll;
    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    stateVars.CL_CategoryCount = result?.data.cL_CategoryCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.categorylist_41919_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    formVars.categorylist_673469_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketId : null
    );

    stateVars.dataSource_673469 = this.state.ASirketAll;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;
    formVars.categorylist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_CategoryCount?.length > 0 ? this.state.CL_CategoryCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CategoryListComponent_981633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CategoryList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "360174",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_791224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CategoryList",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_673469_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CategoryList/CategoryListComponent_673469_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    stateVars.CL_CategoryCount = result?.data.cL_CategoryCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_673469_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_673469_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;
    formVars.categorylist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_CategoryCount?.length > 0 ? this.state.CL_CategoryCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CategoryList/CategoryListComponent_416063_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    stateVars.CL_CategoryCount = result?.data.cL_CategoryCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_416063_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;
    formVars.categorylist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_CategoryCount?.length > 0 ? this.state.CL_CategoryCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_484095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CategoryList/CategoryListComponent_484095_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_484095_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_484095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CategoryForm", "Id", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.CategoryListComponent_814413_onClick1_,
      "CategoryList",
      "CategoryForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "697242",
      null,
      "right",
      null,
      "450px",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  CategoryListComponent_814413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CategoryList/CategoryListComponent_814413_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    stateVars.CL_CategoryCount = result?.data.cL_CategoryCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_814413_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_814413_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;
    formVars.categorylist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_CategoryCount?.length > 0 ? this.state.CL_CategoryCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CategoryForm",
      "Id",
      ReactSystemFunctions.value(this, "categorylist_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.CategoryListComponent_860665_onClick1_,
      "CategoryList",
      "CategoryForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  CategoryListComponent_860665_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CategoryList/CategoryListComponent_860665_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_860665_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_860665_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "categorylist_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.ASirketAll?.length > 0 ? this.state.ASirketAll[0]?.id : null),
        "Guid"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "CategoryList/CategoryListComponent_505853_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.CL_CategoryDeleteById = result?.data.cL_CategoryDeleteById;
        stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

        stateVars.CL_CategoryCount = result?.data.cL_CategoryCount;
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.CategoryListComponent_505853_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;
    formVars.categorylist_172599_total = ReactSystemFunctions.value(
      this,
      this.state.CL_CategoryCount?.length > 0 ? this.state.CL_CategoryCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CategoryListComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_673469_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "categorylist_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CategoryList/CategoryListComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_CategorySearch = result?.data.cL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.CategoryListComponent_172599_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CategoryListComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_399769 = this.state.CL_CategorySearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [360174] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CategoryListPageInit();
    }
    if (diId == 697242) {
      isErrorOccurred = await this.CategoryListComponent_814413_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 256479) {
      isErrorOccurred = await this.CategoryListComponent_860665_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
