import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IClReadOnly_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IClReadOnly_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  CL_ChecklistSelectById: any[];
  CL_SoruEkleCikarListesi: any[];
  CL_SoruYuzdeToplami: any[];
  ChecklistdeOlmayanSubeler: any[];
  ChecklistdeOlanSubeler: any[];
  ChecklisteSubeEkle: any[];
  SetValueOf: string;
  ChecklistdenSubeCikar: number;
  ChecklistYeniVersiyonOlustur: any[];
  NAVIGATE: any;
  ChecklistPublish: any[];
  isComp420980Visible: "visible" | "hidden";
  isComp9544Visible: "visible" | "hidden";
  isComp721350Visible: "visible" | "hidden";
  isCompclreadonly_191492AuthorizationVisible: "visible" | "hidden";
}

export class ClReadOnly_ScreenBase extends React.PureComponent<IClReadOnly_ScreenProps, any> {
  clreadonly_81462_value_kuikaTableRef: React.RefObject<any>;
  clreadonly_117198_value_kuikaSelectBoxRef: React.RefObject<any>;
  clreadonly_356179_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "035f5c0a-393e-4d94-9012-980b73741f31",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Checklist İzleme" },
        { Id: 9944, PropertyName: "value", Value: "Şirket" },
        { Id: 918314, PropertyName: "value", Value: "Kategori" },
        { Id: 6818574, PropertyName: "value", Value: "Checklist Adı" },
        { Id: 617002, PropertyName: "value", Value: "v." },
        { Id: 367015, PropertyName: "value", Value: "Durum" },
        { Id: 591888, PropertyName: "tab", Value: "Sorular" },
        { Id: 81462, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 716835, PropertyName: "title", Value: "Soru Havuzu" },
        { Id: 129614, PropertyName: "value", Value: "[datafield:metin]" },
        { Id: 168368, PropertyName: "title", Value: "Ekli Mi?" },
        { Id: 586392, PropertyName: "title", Value: "Baş" },
        { Id: 53616, PropertyName: "value", Value: "[datafield:terminbaslangic]" },
        { Id: 831160, PropertyName: "title", Value: "Bit" },
        { Id: 137338, PropertyName: "value", Value: "[datafield:terminbitis]" },
        { Id: 74066, PropertyName: "title", Value: "Yüzde" },
        { Id: 400695, PropertyName: "value", Value: "[datafield:yuzdeagirligi]" },
        { Id: 395526, PropertyName: "title", Value: "Tolerans" },
        { Id: 960721, PropertyName: "value", Value: "[datafield:toleranssuresidakika]" },
        { Id: 218354, PropertyName: "value", Value: "-" },
        { Id: 420028, PropertyName: "value", Value: "[datafield:toleranspuanyuzdesi]" },
        { Id: 191492, PropertyName: "tab", Value: "Şubeler" },
        { Id: 117198, PropertyName: "placeholder", Value: "Şube...." },
        { Id: 947198, PropertyName: "label", Value: "EKLE" },
        { Id: 356179, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 675279, PropertyName: "title", Value: "Title" },
        { Id: 110188, PropertyName: "value", Value: "[datafield:subeadi]" },
        { Id: 605277, PropertyName: "title", Value: "Title" },
        { Id: 453393, PropertyName: "value", Value: "Toplam" },
        { Id: 521586, PropertyName: "value", Value: "%" },
        { Id: 9544, PropertyName: "label", Value: "Yeni Versiyon Oluştur" },
        { Id: 721350, PropertyName: "label", Value: "Yayınla" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.clreadonly_81462_value_kuikaTableRef = React.createRef();
    this.clreadonly_117198_value_kuikaSelectBoxRef = React.createRef();
    this.clreadonly_356179_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      CL_ChecklistSelectById: [],
      CL_SoruEkleCikarListesi: [],
      CL_SoruYuzdeToplami: [],
      ChecklistdeOlmayanSubeler: [],
      ChecklistdeOlanSubeler: [],
      ChecklisteSubeEkle: [],
      SetValueOf: "",
      ChecklistdenSubeCikar: "",
      ChecklistYeniVersiyonOlustur: [],
      NAVIGATE: "",
      ChecklistPublish: [],
      isComp420980Visible: "hidden",
      isComp9544Visible: "hidden",
      isComp721350Visible: "hidden",
      isCompclreadonly_191492AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("clreadonly", "clreadonly");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ClReadOnlyPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompclreadonly_191492AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("clreadonly", "clreadonly");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("clreadonly", "clreadonly");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ClReadOnlyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ClReadOnlyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClReadOnly/ClReadOnlyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.CL_ChecklistSelectById = result?.data.cL_ChecklistSelectById;
    formVars.clreadonly_727415_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.sirket : null
    );
    formVars.clreadonly_212848_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.kategori : null
    );
    formVars.clreadonly_395646_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.tanimi : null
    );
    formVars.clreadonly_90447_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.versiyon : null
    );
    formVars.clreadonly_558398_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.durumTanim : null
    );
    stateVars.isComp420980Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0
            ? stateVars.CL_ChecklistSelectById[0]?.yeniVersiyonIzinVarmi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp721350Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.durum : null
        ),
        "DRAFT"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CL_SoruEkleCikarListesi = result?.data.cL_SoruEkleCikarListesi;

    stateVars.CL_SoruYuzdeToplami = result?.data.cL_SoruYuzdeToplami;
    formVars.clreadonly_357117_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_SoruYuzdeToplami?.length > 0 ? stateVars.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
    );
    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clreadonly_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClReadOnlyPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClReadOnlyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.clreadonly_727415_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.sirket : null
    );

    formVars.clreadonly_212848_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.kategori : null
    );

    formVars.clreadonly_395646_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.tanimi : null
    );

    formVars.clreadonly_90447_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.versiyon : null
    );

    formVars.clreadonly_558398_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.durumTanim : null
    );

    stateVars.isComp420980Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_81462 = this.state.CL_SoruEkleCikarListesi;

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;
    formVars.clreadonly_357117_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruYuzdeToplami?.length > 0 ? this.state.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
    );

    stateVars.isComp9544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0
            ? this.state.CL_ChecklistSelectById[0]?.yeniVersiyonIzinVarmi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp721350Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.durum : null
        ),
        "DRAFT"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ClReadOnlyComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClReadOnlyComponent_947198_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clreadonly_117198_value", "value", "ChecklistdeOlmayanSubeler", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "clreadonly_117198_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      ChecklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clreadonly_117198_value", "value", "ChecklistdeOlmayanSubeler", "id", "id")
        ),
        "Guid"
      ),
      checklistId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      checklistId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.sirketId : null
        ),
        "Guid"
      ),
      bolgeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClReadOnly/ClReadOnlyComponent_947198_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklisteSubeEkle = result?.data.checklisteSubeEkle;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clreadonly_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClReadOnlyComponent_947198_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClReadOnlyComponent_947198_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "clreadonly_117198_value", null, null, "Id");

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClReadOnlyComponent_386715_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "clreadonly_356179_value", "csbId"),
        "Guid"
      ),
      checklistId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      checklistId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.sirketId : null
        ),
        "Guid"
      ),
      bolgeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClReadOnly/ClReadOnlyComponent_386715_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistdenSubeCikar = result?.data.checklistdenSubeCikar;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clreadonly_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClReadOnlyComponent_386715_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClReadOnlyComponent_386715_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClReadOnlyComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClReadOnly/ClReadOnlyComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistYeniVersiyonOlustur = result?.data.checklistYeniVersiyonOlustur;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClReadOnlyComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClReadOnlyComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ClForm",
      "ChecklistId",
      ReactSystemFunctions.value(
        this,
        this.state.ChecklistYeniVersiyonOlustur?.length > 0
          ? this.state.ChecklistYeniVersiyonOlustur[0]?.yeniChecklistId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "ClForm",
      "SirketId",
      ReactSystemFunctions.value(
        this,
        this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.sirketId : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.ClReadOnlyComponent_9544_onClick2_,
      "ClReadOnly",
      "ClForm",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "868015",
      null,
      "right",
      null,
      "780px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  ClReadOnlyComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClReadOnlyComponent_721350_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClReadOnly/ClReadOnlyComponent_721350_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistPublish = result?.data.checklistPublish;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClReadOnlyComponent_721350_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClReadOnlyComponent_721350_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [868015] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ClReadOnlyPageInit();
    }
    if (diId == 868015) {
      isErrorOccurred = await this.ClReadOnlyComponent_9544_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
