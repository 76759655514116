import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  AUserExtByUserId: any[];
  RolAll: any[];
  ASirketAll: any[];
  BolgeBySirketId: any[];
  ASubeByBolgeId: any[];
  CategoryBySirket: any[];
  ChangeEnabledOf: any;
  UserParamsByUserId: any[];
  ChangeVisibilityOf: string;
  SetValueOf: string;
  DisableUser: any;
  SetUserToPassive: number;
  SaveUser: any;
  AUserExtSave: number;
  EnableUser: any;
  isComp877047Visible: "visible" | "hidden";
  isComp817259Visible: "visible" | "hidden";
  isComp214728Visible: "visible" | "hidden";
  isComp631485Visible: "visible" | "hidden";
  isComp891770Visible: "visible" | "hidden";
  isComp326835Visible: "visible" | "hidden";
  isComp13703830Enabled: "enabled" | "disabled";
  isCompkullaniciform_877047AuthorizationVisible: "visible" | "hidden";
  isCompkullaniciform_817259AuthorizationVisible: "visible" | "hidden";
  isCompkullaniciform_214728AuthorizationVisible: "visible" | "hidden";
}

export class KullaniciForm_ScreenBase extends React.PureComponent<IKullaniciForm_ScreenProps, any> {
  kullaniciform_575758_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullaniciform_949141_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullaniciform_91369_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullaniciform_98218_value_kuikaSelectBoxRef: React.RefObject<any>;
  kullaniciform_468297_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "ee6f8970-119f-4bb4-86cd-8be3072a0a71",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kullanıcı Tanımı" },
        { Id: 5323147, PropertyName: "value", Value: "Kullanıcı Eposta Adresi" },
        { Id: 13703830, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 999148, PropertyName: "value", Value: "İsim" },
        { Id: 142827, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 492227, PropertyName: "value", Value: "Soyad" },
        { Id: 377017, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 318649, PropertyName: "value", Value: "Role" },
        { Id: 575758, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 711940, PropertyName: "value", Value: "Şirket" },
        { Id: 949141, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 238379, PropertyName: "value", Value: "Bölge" },
        { Id: 91369, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 361617, PropertyName: "value", Value: "Şube" },
        { Id: 98218, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 903626, PropertyName: "value", Value: "Checklist Kategorisi" },
        { Id: 468297, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 93189, PropertyName: "value", Value: "Aktif" },
        { Id: 891770, PropertyName: "value", Value: "Kişi pasife alındı. Kişi Uygulamaya giremez!" },
        { Id: 326835, PropertyName: "label", Value: "SİL" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.kullaniciform_575758_value_kuikaSelectBoxRef = React.createRef();
    this.kullaniciform_949141_value_kuikaSelectBoxRef = React.createRef();
    this.kullaniciform_91369_value_kuikaSelectBoxRef = React.createRef();
    this.kullaniciform_98218_value_kuikaSelectBoxRef = React.createRef();
    this.kullaniciform_468297_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      AUserExtByUserId: [],
      RolAll: [],
      ASirketAll: [],
      BolgeBySirketId: [],
      ASubeByBolgeId: [],
      CategoryBySirket: [],
      ChangeEnabledOf: "",
      UserParamsByUserId: [],
      ChangeVisibilityOf: "",
      SetValueOf: "",
      DisableUser: "",
      SetUserToPassive: 0,
      SaveUser: "",
      AUserExtSave: 0,
      EnableUser: "",
      isComp877047Visible: "hidden",
      isComp817259Visible: "hidden",
      isComp214728Visible: "hidden",
      isComp631485Visible: "hidden",
      isComp891770Visible: "hidden",
      isComp326835Visible: "hidden",
      isComp13703830Enabled: "enabled",
      isCompkullaniciform_877047AuthorizationVisible: "visible",
      isCompkullaniciform_817259AuthorizationVisible: "visible",
      isCompkullaniciform_214728AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KullaniciFormPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullaniciform_877047AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullaniciform_817259AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompkullaniciform_214728AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin"
    ]);

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "SubeAdmin", "BolgeAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("9544");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KullaniciFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kullaniciform_13703830_value: this.state.AUserExtByUserId?.at?.(0)?.userName ?? undefined,
      kullaniciform_142827_value: this.state.AUserExtByUserId?.at?.(0)?.firstName ?? undefined,
      kullaniciform_377017_value: this.state.AUserExtByUserId?.at?.(0)?.lastName ?? undefined,
      kullaniciform_575758_value: this.state.AUserExtByUserId?.at?.(0)?.rolename ?? undefined,
      kullaniciform_949141_value: this.state.AUserExtByUserId?.at?.(0)?.sirketId ?? undefined,
      kullaniciform_91369_value: this.state.AUserExtByUserId?.at?.(0)?.bolgeId ?? undefined,
      kullaniciform_98218_value: this.state.AUserExtByUserId?.at?.(0)?.subeId ?? undefined,
      kullaniciform_468297_value: this.state.AUserExtByUserId?.at?.(0)?.kategoriId ?? undefined,
      kullaniciform_66251_value: this.state.AUserExtByUserId?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  KullaniciFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UserId ?? this.props.screenInputs.userid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KullaniciForm/KullaniciFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.AUserExtByUserId = result?.data.aUserExtByUserId;
    formVars.kullaniciform_13703830_value = ReactSystemFunctions.toString(
      this,
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.userName : null
    );
    formVars.kullaniciform_142827_value = ReactSystemFunctions.toString(
      this,
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.firstName : null
    );
    formVars.kullaniciform_377017_value = ReactSystemFunctions.toString(
      this,
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.lastName : null
    );
    formVars.kullaniciform_575758_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.rolename : null;
    formVars.kullaniciform_575758_options = stateVars.RolAll;
    formVars.kullaniciform_949141_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.sirketId : null;
    formVars.kullaniciform_949141_options = stateVars.ASirketAll;
    formVars.kullaniciform_91369_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.bolgeId : null;
    formVars.kullaniciform_91369_options = stateVars.BolgeBySirketId;
    formVars.kullaniciform_98218_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.subeId : null;
    formVars.kullaniciform_98218_options = stateVars.ASubeByBolgeId;
    formVars.kullaniciform_468297_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.kategoriId : null;
    formVars.kullaniciform_468297_options = stateVars.CategoryBySirket;
    formVars.kullaniciform_66251_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.isActive : null
    );
    stateVars.isComp877047Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp817259Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp214728Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.subeSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631485Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.kategoriSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891770Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.isActive : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.RolAll = result?.data.rolAll;

    formVars.kullaniciform_575758_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.rolename : null;
    formVars.kullaniciform_575758_options = stateVars.RolAll;
    stateVars.ASirketAll = result?.data.aSirketAll;

    formVars.kullaniciform_949141_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.sirketId : null;
    formVars.kullaniciform_949141_options = stateVars.ASirketAll;
    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.kullaniciform_91369_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.bolgeId : null;
    formVars.kullaniciform_91369_options = stateVars.BolgeBySirketId;
    stateVars.ASubeByBolgeId = result?.data.aSubeByBolgeId;

    formVars.kullaniciform_98218_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.subeId : null;
    formVars.kullaniciform_98218_options = stateVars.ASubeByBolgeId;
    stateVars.CategoryBySirket = result?.data.categoryBySirket;

    formVars.kullaniciform_468297_value =
      stateVars.AUserExtByUserId?.length > 0 ? stateVars.AUserExtByUserId[0]?.kategoriId : null;
    formVars.kullaniciform_468297_options = stateVars.CategoryBySirket;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "N")) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp13703830Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "E")) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp13703830Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormPageInit3_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.kullaniciform_13703830_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.userName : null
    );

    formVars.kullaniciform_142827_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.firstName : null
    );

    formVars.kullaniciform_377017_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.lastName : null
    );

    formVars.kullaniciform_575758_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.rolename : null
    );

    stateVars.dataSource_575758 = this.state.RolAll;
    stateVars.isComp877047Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kullaniciform_949141_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketId : null
    );

    stateVars.dataSource_949141 = this.state.ASirketAll;
    stateVars.isComp817259Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kullaniciform_91369_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeId : null
    );

    stateVars.dataSource_91369 = this.state.BolgeBySirketId;
    stateVars.isComp214728Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.subeSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kullaniciform_98218_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.subeId : null
    );

    stateVars.dataSource_98218 = this.state.ASubeByBolgeId;
    stateVars.isComp631485Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.kategoriSecimiGoster : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kullaniciform_468297_value = ReactSystemFunctions.toString(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.kategoriId : null
    );

    stateVars.dataSource_468297 = this.state.CategoryBySirket;
    formVars.kullaniciform_66251_value = ReactSystemFunctions.toBoolean(
      this,
      this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.isActive : null
    );

    stateVars.isComp891770Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.isActive : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KullaniciFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_575758_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_575758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "rol")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciForm/KullaniciFormComponent_575758_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserParamsByUserId = result?.data.userParamsByUserId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_575758_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_575758_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp877047Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp817259Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp214728Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp631485Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_575758_onChange2_();
    });

    return isErrorOccurred;
  };
  KullaniciFormComponent_575758_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UserParamsByUserId?.length > 0 ? this.state.UserParamsByUserId[0]?.sirketSecimiGoster : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp877047Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange3_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_575758_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UserParamsByUserId?.length > 0 ? this.state.UserParamsByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp817259Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange4_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_575758_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UserParamsByUserId?.length > 0 ? this.state.UserParamsByUserId[0]?.subeSecimiGoster : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp214728Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_575758_onChange5_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_575758_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.UserParamsByUserId?.length > 0 ? this.state.UserParamsByUserId[0]?.kategoriSecimiGoster : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp631485Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KullaniciFormComponent_949141_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_949141_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      sirketId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciForm/KullaniciFormComponent_949141_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BolgeBySirketId = result?.data.bolgeBySirketId;

    formVars.kullaniciform_91369_options = stateVars.BolgeBySirketId;
    stateVars.CategoryBySirket = result?.data.categoryBySirket;

    formVars.kullaniciform_468297_options = stateVars.CategoryBySirket;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_949141_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_949141_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kullaniciform_91369_value", null, null, "Id");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kullaniciform_98218_value", null, null, "Id");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kullaniciform_468297_value", null, null, "Id");

    stateVars.dataSource_91369 = this.state.BolgeBySirketId;

    stateVars.dataSource_468297 = this.state.CategoryBySirket;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_91369_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ),
      message: "*",
      formName: "kullaniciform_91369_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_91369_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciForm/KullaniciFormComponent_91369_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ASubeByBolgeId = result?.data.aSubeByBolgeId;

    formVars.kullaniciform_98218_options = stateVars.ASubeByBolgeId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_91369_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_91369_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kullaniciform_98218_value", null, null, "Id");

    stateVars.dataSource_98218 = this.state.ASubeByBolgeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_66251_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp891770Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_66251_onChange1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_66251_onChange1_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_66251_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp891770Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KullaniciFormComponent_326835_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_13703830_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b8cad9e5_1743_e42d_361e_40aaa38b27ce_confirmation",
        this.defaultML,
        "Emin misin?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);

        stateVars.DisableUser = await ReactSystemFunctions.disableUser(
          ReactSystemFunctions.toString(
            this,
            ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
          )
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.KullaniciFormComponent_326835_onClick1_();
        });
      }
    });

    return isErrorOccurred;
  };
  KullaniciFormComponent_326835_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UserId ?? this.props.screenInputs.userid,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciForm/KullaniciFormComponent_326835_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SetUserToPassive = result?.data.setUserToPassive;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.KullaniciFormComponent_326835_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_326835_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_13703830_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_142827_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_142827_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_377017_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_377017_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_575758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_142827_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_377017_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "")
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("042861d8-a086-420d-8b1c-c86050fe3a72", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName("-", "string")
    };

    KuikaAppManager.showSpinner(this);
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "N")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick1_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_575758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ),
      message: "*",
      formName: "kullaniciform_91369_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_949141_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_98218_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.subeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_468297_value", "value", "CategoryBySirket", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_468297_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.kategoriSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "rol")
        ),
        "string"
      ),
      BolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_91369_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        "Guid"
      ),
      KategoriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_468297_value", "value", "CategoryBySirket", "id", "id")
        ),
        "Guid"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.SaveUser?.id),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "N")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AUserExtSave = result?.data.aUserExtSave;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_575758_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        "1"
      ),
      message: "*",
      formName: "kullaniciform_91369_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.bolgeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_949141_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.sirketSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_98218_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.subeSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_468297_value", "value", "CategoryBySirket", "id", "id")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_468297_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.AUserExtByUserId?.length > 0 ? this.state.AUserExtByUserId[0]?.kategoriSecimiGoster : null
        ),
        1
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_575758_value", "value", "RolAll", "rol", "rol")
        ),
        "string"
      ),
      BolgeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_91369_value", "value", "BolgeBySirketId", "id", "id")
        ),
        "Guid"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_949141_value", "value", "ASirketAll", "id", "id")
        ),
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_98218_value", "value", "ASubeByBolgeId", "id", "id")
        ),
        "Guid"
      ),
      KategoriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_468297_value", "value", "CategoryBySirket", "id", "id")
        ),
        "Guid"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UserId ?? this.props.screenInputs.userid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewEdit ?? this.props.screenInputs.newedit, "E")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KullaniciForm/KullaniciFormComponent_9544_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.AUserExtSave = result?.data.aUserExtSave;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_13703830_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick4_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kullaniciform_13703830_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_66251_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.EnableUser = await ReactSystemFunctions.enableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullaniciform_13703830_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.KullaniciFormComponent_9544_onClick5_();
      });
    }

    return isErrorOccurred;
  };
  KullaniciFormComponent_9544_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
