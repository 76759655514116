import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { BolgeList_ScreenBase } from "./bolgelist-base";

import { BolgeForm_Screen } from "../bolgeform/bolgeform";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class BolgeList_Screen extends BolgeList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              bolgelist_458913_value: this.state.MyContext?.at?.(0)?.sirketId ?? undefined,
              bolgelist_416063_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="bolgelist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="84486"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="996029"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="334340"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="981633"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.BolgeListComponent_981633_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(95, 207, 229, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Icon
                        id="221812"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.BolgeListComponent_221812_onClick();
                        }}
                        showCursorPointer
                        iconName="dashboard"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(95, 207, 229, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="923160"
                        value={ReactSystemFunctions.translate(this.ml, 923160, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18pt",
                            color: "rgba(95, 207, 229, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="470942"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="375140"
                      value={this.state.MyContext?.at?.(0)?.sirketBolgeSube ?? undefined}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    ></Label>

                    <Label
                      id="617235"
                      value={ReactSystemFunctions.getMyUsername()}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-right",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(155, 155, 155, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="bolgelist_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KDrawer
                  id="697242"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible697242: false }, () => {
                      this.callCallbackFunction(697242);
                    });
                  }}
                  visible={this.state.NavVisible697242}
                  width="450px"
                  push={true}
                  children={
                    this.state.NavVisible697242 && (
                      <BolgeForm_Screen
                        onClose={() => {
                          this.setState({ NavVisible697242: false }, () => {
                            this.callCallbackFunction(697242);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="256479"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible256479: false }, () => {
                      this.callCallbackFunction(256479);
                    });
                  }}
                  visible={this.state.NavVisible256479}
                  width="450px"
                  push={true}
                  children={
                    this.state.NavVisible256479 && (
                      <BolgeForm_Screen
                        onClose={() => {
                          this.setState({ NavVisible256479: false }, () => {
                            this.callCallbackFunction(256479);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="289117"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible289117: false }, () => {
                      this.callCallbackFunction(289117);
                    });
                  }}
                  visible={this.state.NavVisible289117}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible289117 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible289117: false }, () => {
                            this.callCallbackFunction(289117);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KRow
                  id="121799"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="800376"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="331958"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="44993"
                        xs={24}
                        sm={16}
                        md={16}
                        lg={16}
                        xl={16}
                        xxl={16}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="260356"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="bolgelist_458913_value">
                            <KSelectBox
                              id="458913"
                              onChange={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BolgeListComponent_458913_onChange();
                              }}
                              visibility={this.state.isCompbolgelist_458913AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompbolgelist_458913AuthorizationVisible}
                              kuikaRef={this.bolgelist_458913_value_kuikaSelectBoxRef}
                              options={this.state.ASirketAll}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                458913,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={true}
                              autoClearSearchValue={true}
                              showSearch={false}
                              widthType="fill"
                              containsNullItem={false}
                              sortBy="none"
                              datavaluefield="id"
                              datatextfield="tanim"
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "200px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></KSelectBox>
                          </Form.Item>

                          <Form.Item className="kFormItem" name="bolgelist_416063_value">
                            <TextInput
                              id="416063"
                              onPressEnter={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BolgeListComponent_416063_onPressEnter();
                              }}
                              placeholder={ReactSystemFunctions.translate(
                                this.ml,
                                416063,
                                "placeholder",
                                this.defaultML
                              )}
                              allowClear={true}
                              bordered={true}
                              disabled={false}
                              type="text"
                              iconColor="rgba(0, 0, 0, 1)"
                              formatter="None"
                              isCharOnly={false}
                              autoComplete={true}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "240px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></TextInput>
                          </Form.Item>

                          <Icon
                            id="484095"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.BolgeListComponent_484095_onClick();
                            }}
                            showCursorPointer
                            iconName="search"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(95, 207, 229, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KCol>

                      <KCol
                        id="775216"
                        xs={24}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <Button
                          id="814413"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BolgeListComponent_814413_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 814413, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 4,
                              borderTopRightRadius: 4,
                              borderBottomRightRadius: 4,
                              borderBottomLeftRadius: 4,
                              backgroundColor: "rgba(95, 207, 229, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 16,
                              paddingBottom: 8,
                              paddingLeft: 16,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "16px",
                              color: "rgba(255, 255, 255, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Button>
                      </KCol>
                    </KRow>

                    <KRow
                      id="782760"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="117562"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="399769"
                          kuikaRef={this.bolgelist_399769_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.ABolgeSearch}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            399769,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          searchable={false}
                          sorter={false}
                          filtering={false}
                          pagination={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="500856"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(155, 155, 155, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="489075"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "30px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="5501033"
                              visibility={this.state.isCompbolgelist_5501033AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompbolgelist_5501033AuthorizationVisible}
                              title={ReactSystemFunctions.translate(this.ml, 5501033, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="9342814"
                                value="[datafield:sirketAdi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="116158"
                              title={ReactSystemFunctions.translate(this.ml, 116158, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="704218"
                                value="[datafield:tanim]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="792509"
                              title={ReactSystemFunctions.translate(this.ml, 792509, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "120px",
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="893506"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="860665"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.BolgeListComponent_860665_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="create"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(95, 207, 229, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>

                                <Icon
                                  id="505853"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.BolgeListComponent_505853_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="delete"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(95, 207, 229, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </HorizontalStack>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>

                        <KPagination
                          id="172599"
                          onChange={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BolgeListComponent_172599_onChange();
                          }}
                          kuikaRef={this.bolgelist_172599_value_kuikaPaginationRef}
                          total={this.state.BolgeCount?.at?.(0)?.adet ?? undefined}
                          pageSize={10}
                          defaultCurrent={1}
                          current={1}
                          defaultPageSize={10}
                          disabled={false}
                          hideOnSinglePage={true}
                          showLessItems={false}
                          showQuickJumper={false}
                          showSizeChanger={true}
                          showFirstPageButton={true}
                          showLastPageButton={false}
                          showNumbers={true}
                          showText={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></KPagination>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(BolgeList_Screen)))))
);
export { tmp as BolgeList_Screen };
//export default tmp;
//export { tmp as BolgeList_Screen };
