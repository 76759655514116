import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IClForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IClForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  ASirketSelectById: any[];
  CategoryBySirket: any[];
  CL_ChecklistSelectById: any[];
  CL_SoruEkleCikarListesi: any[];
  CL_SoruYuzdeToplami: any[];
  ChecklistdeOlmayanSubeler: any[];
  ChecklistdeOlanSubeler: any[];
  NAVIGATE: any;
  CL_SoruCikar: number;
  ChecklisteSubeEkle: any[];
  SetValueOf: string;
  ChecklistdenSubeCikar: number;
  CL_ChecklistSave: any[];
  ChangeVisibilityOf: string;
  ChecklistPublish: any[];
  isComp420980Visible: "visible" | "hidden";
  isComp9544Visible: "visible" | "hidden";
  isComp806198Visible: "visible" | "hidden";
  isComp142631Visible: "visible" | "hidden";
  isComp534383Visible: "visible" | "hidden";
  isComp970826Visible: "visible" | "hidden";
  isComp26129450Visible: "visible" | "hidden";
  isComp766919Visible: "visible" | "hidden";
  isCompclform_191492AuthorizationVisible: "visible" | "hidden";
}

export class ClForm_ScreenBase extends React.PureComponent<IClForm_ScreenProps, any> {
  clform_534383_value_kuikaSelectBoxRef: React.RefObject<any>;
  clform_81462_value_kuikaTableRef: React.RefObject<any>;
  clform_117198_value_kuikaSelectBoxRef: React.RefObject<any>;
  clform_356179_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "1f466376-ac4d-4db7-811f-8a926eff8e9b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Checklist Tanımı" },
        { Id: 9944, PropertyName: "value", Value: "Şirket" },
        { Id: 918314, PropertyName: "value", Value: "Kategori" },
        { Id: 534383, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6818574, PropertyName: "value", Value: "Checklist Adı" },
        { Id: 26129450, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 633120, PropertyName: "value", Value: "Durum" },
        { Id: 591888, PropertyName: "tab", Value: "Sorular" },
        { Id: 81462, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 716835, PropertyName: "title", Value: "Soru Havuzu" },
        { Id: 129614, PropertyName: "value", Value: "[datafield:metin]" },
        { Id: 168368, PropertyName: "title", Value: "Ekli Mi?" },
        { Id: 586392, PropertyName: "title", Value: "Baş" },
        { Id: 53616, PropertyName: "value", Value: "[datafield:terminbaslangic]" },
        { Id: 831160, PropertyName: "title", Value: "Bit" },
        { Id: 137338, PropertyName: "value", Value: "[datafield:terminbitis]" },
        { Id: 74066, PropertyName: "title", Value: "Yüzde" },
        { Id: 400695, PropertyName: "value", Value: "[datafield:yuzdeagirligi]" },
        { Id: 395526, PropertyName: "title", Value: "Tolerans" },
        { Id: 960721, PropertyName: "value", Value: "[datafield:toleranssuresidakika]" },
        { Id: 218354, PropertyName: "value", Value: "-" },
        { Id: 420028, PropertyName: "value", Value: "[datafield:toleranspuanyuzdesi]" },
        { Id: 182567, PropertyName: "title", Value: "Güncelle" },
        { Id: 193649, PropertyName: "title", Value: "Aynı sorudan ekle" },
        { Id: 191492, PropertyName: "tab", Value: "Şubeler" },
        { Id: 117198, PropertyName: "placeholder", Value: "Şube...." },
        { Id: 947198, PropertyName: "label", Value: "EKLE" },
        { Id: 356179, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 675279, PropertyName: "title", Value: "Title" },
        { Id: 110188, PropertyName: "value", Value: "[datafield:subeadi]" },
        { Id: 605277, PropertyName: "title", Value: "Title" },
        { Id: 453393, PropertyName: "value", Value: "Toplam" },
        { Id: 521586, PropertyName: "value", Value: "%" },
        { Id: 9544, PropertyName: "label", Value: "Oluştur" },
        { Id: 806198, PropertyName: "label", Value: "Güncelle" },
        { Id: 142631, PropertyName: "label", Value: "Güncelle ve Yayınla" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.clform_534383_value_kuikaSelectBoxRef = React.createRef();
    this.clform_81462_value_kuikaTableRef = React.createRef();
    this.clform_117198_value_kuikaSelectBoxRef = React.createRef();
    this.clform_356179_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ASirketSelectById: [],
      CategoryBySirket: [],
      CL_ChecklistSelectById: [],
      CL_SoruEkleCikarListesi: [],
      CL_SoruYuzdeToplami: [],
      ChecklistdeOlmayanSubeler: [],
      ChecklistdeOlanSubeler: [],
      NAVIGATE: "",
      CL_SoruCikar: 0,
      ChecklisteSubeEkle: [],
      SetValueOf: "",
      ChecklistdenSubeCikar: "",
      CL_ChecklistSave: [],
      ChangeVisibilityOf: "",
      ChecklistPublish: [],
      isComp420980Visible: "hidden",
      isComp9544Visible: "hidden",
      isComp806198Visible: "hidden",
      isComp142631Visible: "hidden",
      isComp534383Visible: "hidden",
      isComp970826Visible: "hidden",
      isComp26129450Visible: "hidden",
      isComp766919Visible: "hidden",
      isCompclform_191492AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("clform", "clform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ClFormPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompclform_191492AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("clform", "clform");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("clform", "clform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ClFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      clform_534383_value: this.state.CL_ChecklistSelectById?.at?.(0)?.categoryId ?? undefined,
      clform_26129450_value: this.state.CL_ChecklistSelectById?.at?.(0)?.tanimi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ClFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      checklistId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      checklistId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      checklistId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ASirketSelectById = result?.data.aSirketSelectById;
    formVars.clform_727415_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASirketSelectById?.length > 0 ? stateVars.ASirketSelectById[0]?.tanim : null
    );
    stateVars.CategoryBySirket = result?.data.categoryBySirket;

    formVars.clform_534383_value =
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.categoryId : null;
    formVars.clform_534383_options = stateVars.CategoryBySirket;
    stateVars.CL_ChecklistSelectById = result?.data.cL_ChecklistSelectById;
    formVars.clform_534383_value =
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.categoryId : null;
    formVars.clform_534383_options = stateVars.CategoryBySirket;
    formVars.clform_970826_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.kategori : null
    );
    formVars.clform_26129450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.tanimi : null
    );
    formVars.clform_766919_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.tanimi : null
    );
    formVars.clform_877688_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.durumTanim : null
    );
    stateVars.isComp534383Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970826Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp26129450Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp766919Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420980Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9544Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp806198Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142631Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CL_ChecklistSelectById?.length > 0 ? stateVars.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CL_SoruEkleCikarListesi = result?.data.cL_SoruEkleCikarListesi;

    stateVars.CL_SoruYuzdeToplami = result?.data.cL_SoruYuzdeToplami;
    formVars.clform_211097_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_SoruYuzdeToplami?.length > 0 ? stateVars.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
    );
    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clform_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.clform_727415_value = ReactSystemFunctions.toString(
      this,
      this.state.ASirketSelectById?.length > 0 ? this.state.ASirketSelectById[0]?.tanim : null
    );

    formVars.clform_534383_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.categoryId : null
    );

    stateVars.dataSource_534383 = this.state.CategoryBySirket;
    stateVars.isComp534383Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.clform_970826_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.kategori : null
    );

    stateVars.isComp970826Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.clform_26129450_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.tanimi : null
    );

    stateVars.isComp26129450Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.clform_766919_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.tanimi : null
    );

    stateVars.isComp766919Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.versiyon : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.clform_877688_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.durumTanim : null
    );

    stateVars.isComp420980Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_81462 = this.state.CL_SoruEkleCikarListesi;

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;
    formVars.clform_211097_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_SoruYuzdeToplami?.length > 0 ? this.state.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
    );

    stateVars.isComp9544Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp806198Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142631Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.CL_ChecklistSelectById?.length > 0 ? this.state.CL_ChecklistSelectById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ClFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "0")) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "ClSoruEkleme",
        "ChecklistId",
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid
      );
      KuikaAppManager.addToPageInputVariables(
        "ClSoruEkleme",
        "SoruId",
        ReactSystemFunctions.value(this, "clform_81462_value", "id")
      );
      KuikaAppManager.addToPageInputVariables("ClSoruEkleme", "IsDuplicate", false);
      KuikaAppManager.addToPageInputVariables("ClSoruEkleme", "ChecklistSoruId", Guid.create().toString());

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.ClFormComponent_755_onChange1_,
        "ClForm",
        "ClSoruEkleme",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "171954",
        null,
        null,
        "true",
        "800px",
        "70vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange1_();
      });
    }

    return isErrorOccurred;
  };
  ClFormComponent_755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CLSoruId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "clform_81462_value", "clsSoruId"),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_755_onChange1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CL_SoruCikar = result?.data.cL_SoruCikar;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_755_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_755_onChange2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CL_SoruEkleCikarListesi = result?.data.cL_SoruEkleCikarListesi;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_755_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_755_onChange3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CL_SoruYuzdeToplami = result?.data.cL_SoruYuzdeToplami;
      formVars.clform_211097_value = ReactSystemFunctions.toString(
        this,
        stateVars.CL_SoruYuzdeToplami?.length > 0 ? stateVars.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
      );
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_755_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange5_();
      });
    }

    return isErrorOccurred;
  };
  ClFormComponent_755_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      stateVars.dataSource_81462 = this.state.CL_SoruEkleCikarListesi;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_755_onChange6_();
      });
    }

    return isErrorOccurred;
  };
  ClFormComponent_755_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "clform_81462_value", "ekliMi"), "1")) {
      formVars.clform_211097_value = ReactSystemFunctions.toString(
        this,
        this.state.CL_SoruYuzdeToplami?.length > 0 ? this.state.CL_SoruYuzdeToplami[0]?.yuzdeToplami : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ClFormComponent_783131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ClSoruEkleme",
      "ChecklistId",
      this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid
    );
    KuikaAppManager.addToPageInputVariables(
      "ClSoruEkleme",
      "SoruId",
      ReactSystemFunctions.value(this, "clform_81462_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("ClSoruEkleme", "IsDuplicate", true);
    KuikaAppManager.addToPageInputVariables(
      "ClSoruEkleme",
      "ChecklistSoruId",
      ReactSystemFunctions.value(this, "clform_81462_value", "clsSoruId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClForm",
      "ClSoruEkleme",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "117119",
      null,
      null,
      "true",
      "800px",
      "60vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_895725_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ClSoruEkleme",
      "ChecklistId",
      this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid
    );
    KuikaAppManager.addToPageInputVariables(
      "ClSoruEkleme",
      "SoruId",
      ReactSystemFunctions.value(this, "clform_81462_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("ClSoruEkleme", "IsDuplicate", true);
    KuikaAppManager.addToPageInputVariables("ClSoruEkleme", "ChecklistSoruId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ClForm",
      "ClSoruEkleme",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "47825",
      null,
      null,
      "true",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_947198_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_117198_value", "value", "ChecklistdeOlmayanSubeler", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_117198_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      ChecklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clform_117198_value", "value", "ChecklistdeOlmayanSubeler", "id", "id")
        ),
        "Guid"
      ),
      checklistId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      checklistId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      bolgeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_947198_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklisteSubeEkle = result?.data.checklisteSubeEkle;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clform_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormComponent_947198_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_947198_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "clform_117198_value", null, null, "Id");

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_386715_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "clform_356179_value", "csbId"),
        "Guid"
      ),
      checklistId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      bolgeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      ),
      checklistId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      sirketId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      bolgeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.bolgeId : null),
        "Guid"
      ),
      subeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_386715_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistdenSubeCikar = result?.data.checklistdenSubeCikar;
    stateVars.ChecklistdeOlanSubeler = result?.data.checklistdeOlanSubeler;

    stateVars.ChecklistdeOlmayanSubeler = result?.data.checklistdeOlmayanSubeler;

    formVars.clform_117198_options = stateVars.ChecklistdeOlmayanSubeler;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormComponent_386715_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_386715_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_117198 = this.state.ChecklistdeOlmayanSubeler;

    stateVars.dataSource_356179 = this.state.ChecklistdeOlanSubeler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_26129450_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_534383_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        "string"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      CategoryId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_ChecklistSave = result?.data.cL_ChecklistSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      ChecklistId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      SubeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.subeId : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_9544_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.ChecklisteSubeEkle = result?.data.checklisteSubeEkle;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.ClFormComponent_9544_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.ClFormComponent_9544_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp420980Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp9544Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp806198Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp142631Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_806198_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_26129450_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_534383_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        "string"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      CategoryId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_806198_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_ChecklistSave = result?.data.cL_ChecklistSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormComponent_806198_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_806198_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ClFormComponent_142631_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_26129450_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "clform_534383_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "clform_26129450_value", "value", "", "", "")
        ),
        "string"
      ),
      SirketId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SirketId ?? this.props.screenInputs.sirketid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      ),
      CategoryId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "clform_534383_value", "value", "CategoryBySirket", "id", "id")
        ),
        "Guid"
      ),
      checklistId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistId ?? this.props.screenInputs.checklistid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ClForm/ClFormComponent_142631_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_ChecklistSave = result?.data.cL_ChecklistSave;
    stateVars.ChecklistPublish = result?.data.checklistPublish;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.ClFormComponent_142631_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ClFormComponent_142631_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [171954, 117119, 47825] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ClFormPageInit();
    }
    if (diId == 171954) {
      isErrorOccurred = await this.ClFormComponent_755_onChange1_();
      if (isErrorOccurred) return true;
    }
  }
}
