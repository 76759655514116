import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDenetimForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDenetimForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  CL_GunlukById: any[];
  ChecklistGunlukSorulari: any[];
  GunlukChecklistSorularCount: any[];
  NAVIGATE: any;
  isCompdenetimform_280123AuthorizationVisible: "visible" | "hidden";
}

export class DenetimForm_ScreenBase extends React.PureComponent<IDenetimForm_ScreenProps, any> {
  denetimform_81462_value_kuikaTableRef: React.RefObject<any>;
  denetimform_34674_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "0e4602fd-fd44-4b29-8eb1-bdd5742bb420",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Denetim" },
        { Id: 918314, PropertyName: "value", Value: "Kule / Mutfak" },
        { Id: 6818574, PropertyName: "value", Value: "Checklist Adı" },
        { Id: 802760, PropertyName: "value", Value: "Tarih" },
        { Id: 81462, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 795712, PropertyName: "title", Value: "Tip" },
        { Id: 74066, PropertyName: "title", Value: "Denetleme" },
        { Id: 731219, PropertyName: "title", Value: "Durum" },
        { Id: 174489, PropertyName: "title", Value: "Skor" },
        { Id: 166208, PropertyName: "value", Value: "[datafield:yuzdeagirligi]" },
        { Id: 716835, PropertyName: "title", Value: "Soru" },
        { Id: 129614, PropertyName: "value", Value: "[datafield:sorumetni]" },
        { Id: 625419, PropertyName: "title", Value: "Cevap" },
        { Id: 238284, PropertyName: "value", Value: "[datafield:cevap]" },
        { Id: 754405, PropertyName: "value", Value: "[datafield:tamamlanamamanedeni]" },
        { Id: 586392, PropertyName: "title", Value: "Denetim" },
        { Id: 53616, PropertyName: "value", Value: "[datafield:sondenetimnotu]" },
        { Id: 76001, PropertyName: "title", Value: "Başlangıç" },
        { Id: 975321, PropertyName: "value", Value: "[datafield:terminbaslangic]" },
        { Id: 831160, PropertyName: "title", Value: "Bitiş" },
        { Id: 137338, PropertyName: "value", Value: "[datafield:terminbitis]" },
        { Id: 409967, PropertyName: "title", Value: "Gerçekleşme" },
        { Id: 44242, PropertyName: "value", Value: "[datafield:soncevapzamani]" },
        { Id: 453393, PropertyName: "value", Value: "Puan" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.denetimform_81462_value_kuikaTableRef = React.createRef();
    this.denetimform_34674_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      CL_GunlukById: [],
      ChecklistGunlukSorulari: [],
      GunlukChecklistSorularCount: [],
      NAVIGATE: "",
      isCompdenetimform_280123AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("denetimform", "denetimform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DenetimFormPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompdenetimform_280123AuthorizationVisible", [
      "26752c40-3965-9e42-b504-79c0a1a7fc26",
      "Denetci"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("denetimform", "denetimform");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "BolgeAdmin", "Denetci"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("denetimform", "denetimform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DenetimFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DenetimFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistGunlukId ?? this.props.screenInputs.checklistgunlukid,
        "Guid"
      ),
      checklistGunlukId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistGunlukId ?? this.props.screenInputs.checklistgunlukid,
        "Guid"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimform_34674_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimform_34674_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_2: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      clGunlukId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistGunlukId ?? this.props.screenInputs.checklistgunlukid,
        "Guid"
      ),
      KapatilanlaridaGoster_3: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DenetimForm/DenetimFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.CL_GunlukById = result?.data.cL_GunlukById;
    formVars.denetimform_133517_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.kategori : null
    );
    formVars.denetimform_858498_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.checklistAdi : null
    );
    formVars.denetimform_567621_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.atanmaTarihi : null
    );
    formVars.denetimform_521586_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.gunSonuPuani : null
    );
    stateVars.ChecklistGunlukSorulari = result?.data.checklistGunlukSorulari;

    stateVars.GunlukChecklistSorularCount = result?.data.gunlukChecklistSorularCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.denetimform_133517_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.kategori : null
    );

    formVars.denetimform_858498_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.checklistAdi : null
    );

    formVars.denetimform_567621_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.atanmaTarihi : null
    );

    stateVars.dataSource_81462 = this.state.ChecklistGunlukSorulari;
    formVars.denetimform_34674_total = ReactSystemFunctions.value(
      this,
      this.state.GunlukChecklistSorularCount?.length > 0
        ? this.state.GunlukChecklistSorularCount[0]?.sorularCount
        : null
    );

    formVars.denetimform_521586_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.gunSonuPuani : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DenetimFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimFormComponent_783131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DenetimNotlar",
      "GunlukCevapId",
      ReactSystemFunctions.value(this, "denetimform_81462_value", "cevapId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DenetimForm",
      "DenetimNotlar",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "578766",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimFormComponent_280123_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DenetimDuzeltme",
      "SoruId",
      ReactSystemFunctions.value(this, "denetimform_81462_value", "soruId")
    );
    KuikaAppManager.addToPageInputVariables(
      "DenetimDuzeltme",
      "GunlukCevapId",
      ReactSystemFunctions.value(this, "denetimform_81462_value", "cevapId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.DenetimFormComponent_280123_onClick1_,
      "DenetimForm",
      "DenetimDuzeltme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "919001",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  DenetimFormComponent_280123_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistGunlukId ?? this.props.screenInputs.checklistgunlukid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DenetimForm/DenetimFormComponent_280123_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CL_GunlukById = result?.data.cL_GunlukById;
    formVars.denetimform_133517_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.kategori : null
    );
    formVars.denetimform_858498_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.checklistAdi : null
    );
    formVars.denetimform_567621_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.atanmaTarihi : null
    );
    formVars.denetimform_521586_value = ReactSystemFunctions.toString(
      this,
      stateVars.CL_GunlukById?.length > 0 ? stateVars.CL_GunlukById[0]?.gunSonuPuani : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimFormComponent_280123_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimFormComponent_280123_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.denetimform_133517_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.kategori : null
    );

    formVars.denetimform_858498_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.checklistAdi : null
    );

    formVars.denetimform_567621_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.atanmaTarihi : null
    );

    formVars.denetimform_521586_value = ReactSystemFunctions.toString(
      this,
      this.state.CL_GunlukById?.length > 0 ? this.state.CL_GunlukById[0]?.gunSonuPuani : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimFormComponent_34674_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checklistGunlukId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ChecklistGunlukId ?? this.props.screenInputs.checklistgunlukid,
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimform_34674_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimform_34674_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KapatilanlaridaGoster_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DenetimForm/DenetimFormComponent_34674_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChecklistGunlukSorulari = result?.data.checklistGunlukSorulari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimFormComponent_34674_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimFormComponent_34674_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_81462 = this.state.ChecklistGunlukSorulari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [578766, 919001] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DenetimFormPageInit();
    }
    if (diId == 919001) {
      isErrorOccurred = await this.DenetimFormComponent_280123_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
