import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePagecopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePagecopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  AvaneStatistics: any[];
  SubeStatistics: any[];
  SubeBazliPuanOrtalamalari: any[];
  SubeBazliTamamlanmamisIsler: any[];
  NAVIGATE: any;
  isComp356196Visible: "visible" | "hidden";
  isComphomepagecopy_519696AuthorizationVisible: "visible" | "hidden";
  isComphomepagecopy_356196AuthorizationVisible: "visible" | "hidden";
  isComphomepagecopy_948692AuthorizationVisible: "visible" | "hidden";
  isComphomepagecopy_452015AuthorizationVisible: "visible" | "hidden";
}

export class HomePagecopy_ScreenBase extends React.PureComponent<IHomePagecopy_ScreenProps, any> {
  homepagecopy_206628_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6728e8ef-0713-4d73-966f-d33084e98e70",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 964623, PropertyName: "value", Value: "Pano" },
        { Id: 789025, PropertyName: "value", Value: "Şirket Adedi" },
        { Id: 186415, PropertyName: "value", Value: "Bölge Adedi" },
        { Id: 433441, PropertyName: "value", Value: "Şube Adedi" },
        { Id: 158690, PropertyName: "value", Value: "Kullanıcı Adedi" },
        { Id: 830132, PropertyName: "value", Value: "Checklist Adedi" },
        { Id: 700621, PropertyName: "value", Value: "Soru Adedi" },
        {
          Id: 480824,
          PropertyName: "value",
          Value: "ŞUBENİN GÜNLÜK CHECKLİSTLERİNİN KATEGORİ BAZINDAKİ İLERLEME DURUMU GÖSTERİLİR."
        },
        { Id: 206628, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 573422, PropertyName: "value", Value: "[datafield:kategoriadi]" },
        { Id: 608905, PropertyName: "value", Value: "[datafield:bugunbasarilisoruadedi]" },
        { Id: 134533, PropertyName: "value", Value: "/" },
        { Id: 81905, PropertyName: "value", Value: "[datafield:bugunsoruadedi]" },
        { Id: 153541, PropertyName: "value", Value: "Şube bazlı Ortalama" },
        { Id: 401470, PropertyName: "value", Value: "Şube bazlı Tamamlanmama" },
        { Id: 224074, PropertyName: "tab", Value: "Tab Item" },
        { Id: 55694, PropertyName: "tab", Value: "Tab Item" },
        { Id: 790980, PropertyName: "tab", Value: "Tab Item" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepagecopy_206628_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      AvaneStatistics: [],
      SubeStatistics: [],
      SubeBazliPuanOrtalamalari: [],
      SubeBazliTamamlanmamisIsler: [],
      NAVIGATE: "",
      isComp356196Visible: "hidden",
      isComphomepagecopy_519696AuthorizationVisible: "visible",
      isComphomepagecopy_356196AuthorizationVisible: "visible",
      isComphomepagecopy_948692AuthorizationVisible: "visible",
      isComphomepagecopy_452015AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepagecopy", "homepagecopy");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HomePagecopyPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepagecopy_519696AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepagecopy_356196AuthorizationVisible", [
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepagecopy_948692AuthorizationVisible", [
      "2e10a6c4-612b-8d67-1069-7550cf40ed0a",
      "d8b59878-3f20-ed40-8291-3f85029a6bf0",
      "f1b2ed4e-5bc6-0b73-66ad-03207400a89f",
      "fc3bbb10-d6ac-5d71-12ff-1c94402edb2c",
      "SubeAdmin",
      "Sube"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComphomepagecopy_452015AuthorizationVisible", [
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "e61f83d2-5998-4fab-ba66-c7716f28a197",
      "26752c40-3965-9e42-b504-79c0a1a7fc26",
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "AvaneAdmin",
      "SirketAdmin",
      "BolgeAdmin",
      "Denetci"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("homepagecopy", "homepagecopy");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepagecopy", "homepagecopy");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagecopyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HomePagecopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePagecopy/HomePagecopyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.homepagecopy_602282_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.sirketBolgeSube : null
    );
    stateVars.isComp356196Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(this, stateVars.MyContext?.length > 0 ? stateVars.MyContext[0]?.roleName : null),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.AvaneStatistics = result?.data.avaneStatistics;
    formVars.homepagecopy_729308_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.sirketAdedi : null
    );
    formVars.homepagecopy_595724_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.bolgeAdedi : null
    );
    formVars.homepagecopy_465303_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.subeAdedi : null
    );
    formVars.homepagecopy_787594_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.kullaniciAdedi : null
    );
    formVars.homepagecopy_646865_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.checklistAdedi : null
    );
    formVars.homepagecopy_490745_value = ReactSystemFunctions.toString(
      this,
      stateVars.AvaneStatistics?.length > 0 ? stateVars.AvaneStatistics[0]?.soruAdedi : null
    );
    stateVars.SubeStatistics = result?.data.subeStatistics;

    stateVars.SubeBazliPuanOrtalamalari = result?.data.subeBazliPuanOrtalamalari;

    stateVars.SubeBazliTamamlanmamisIsler = result?.data.subeBazliTamamlanmamisIsler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagecopyPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagecopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.homepagecopy_602282_value = ReactSystemFunctions.toString(
      this,
      this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.sirketBolgeSube : null
    );

    stateVars.isComp356196Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.roleName : null
        ),
        "AvaneAdmin"
      ) === true
        ? "visible"
        : "hidden";
    formVars.homepagecopy_729308_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.sirketAdedi : null
    );

    formVars.homepagecopy_595724_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.bolgeAdedi : null
    );

    formVars.homepagecopy_465303_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.subeAdedi : null
    );

    formVars.homepagecopy_787594_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.kullaniciAdedi : null
    );

    formVars.homepagecopy_646865_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.checklistAdedi : null
    );

    formVars.homepagecopy_490745_value = ReactSystemFunctions.toString(
      this,
      this.state.AvaneStatistics?.length > 0 ? this.state.AvaneStatistics[0]?.soruAdedi : null
    );

    stateVars.dataSource_206628 = this.state.SubeStatistics;
    formVars.homepagecopy_608905_value = ReactSystemFunctions.value(
      this,
      this.state.SubeStatistics?.length > 0 ? this.state.SubeStatistics[0]?.bugunBasariliSoruAdedi : null
    );

    formVars.homepagecopy_81905_value = ReactSystemFunctions.value(
      this,
      this.state.SubeStatistics?.length > 0 ? this.state.SubeStatistics[0]?.bugunSoruAdedi : null
    );

    stateVars.dataSource_20742 = this.state.SubeBazliPuanOrtalamalari;

    stateVars.dataSource_272288 = this.state.SubeBazliTamamlanmamisIsler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePagecopyComponent_556738_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "728677",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_349249_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "SirketList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_970840_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "BolgeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_152080_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "SubeList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_787594_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "KullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_660138_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "KullaniciList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_781511_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "ClList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePagecopyComponent_508945_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePagecopy",
      "SoruList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [728677, , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagecopyPageInit();
    }
  }
}
