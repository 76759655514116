import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDenetimDuzeltme_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDenetimDuzeltme_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  ASoruSelectById: any[];
  GunlukCevapById: any[];
  DownloadFile: string;
  Notify: boolean;
  SetValueOf: string;
  Denetim: number;
  GunSonuGuncelle: any[];
  DenetimTarihiSetEt: number;
  isComp176331Visible: "visible" | "hidden";
  isComp630498Visible: "visible" | "hidden";
  isComp24952Visible: "visible" | "hidden";
  isComp984455Visible: "visible" | "hidden";
  isComp704458Visible: "visible" | "hidden";
  isComp382573Visible: "visible" | "hidden";
  isComp554489Visible: "visible" | "hidden";
  isComp364724Visible: "visible" | "hidden";
  isCompdenetimduzeltme_9544AuthorizationVisible: "visible" | "hidden";
}

export class DenetimDuzeltme_ScreenBase extends React.PureComponent<IDenetimDuzeltme_ScreenProps, any> {
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "4e114422-13ce-4a4c-bc5e-66b42658cfca",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Denetim" },
        { Id: 194003, PropertyName: "value", Value: "Soru Metni" },
        { Id: 955345, PropertyName: "value", Value: "Durum" },
        { Id: 437320, PropertyName: "value", Value: "Açıklama" },
        { Id: 660925, PropertyName: "value", Value: "İmza" },
        { Id: 979895, PropertyName: "value", Value: "Resim" },
        { Id: 831386, PropertyName: "value", Value: "File Upload" },
        { Id: 861152, PropertyName: "label", Value: "Download" },
        { Id: 142841, PropertyName: "value", Value: "Denetim Notu" },
        { Id: 256785, PropertyName: "placeholder", Value: "METİN" },
        { Id: 364724, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 433959, PropertyName: "label", Value: "Geçersiz" },
        { Id: 9544, PropertyName: "label", Value: "Geçerli" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ASoruSelectById: [],
      GunlukCevapById: [],
      DownloadFile: "",
      Notify: false,
      SetValueOf: "",
      Denetim: 0,
      GunSonuGuncelle: [],
      DenetimTarihiSetEt: "",
      isComp176331Visible: "hidden",
      isComp630498Visible: "hidden",
      isComp24952Visible: "hidden",
      isComp984455Visible: "hidden",
      isComp704458Visible: "hidden",
      isComp382573Visible: "hidden",
      isComp554489Visible: "hidden",
      isComp364724Visible: "hidden",
      isCompdenetimduzeltme_9544AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("denetimduzeltme", "denetimduzeltme");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DenetimDuzeltmePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompdenetimduzeltme_9544AuthorizationVisible", [
      "26752c40-3965-9e42-b504-79c0a1a7fc26",
      "9447fc5e-40ce-82e7-f649-9e9c3b51932a",
      "41d1f7d7-b208-5172-6869-ba0c7a71f353",
      "AvaneAdmin",
      "SirketAdmin",
      "Denetci"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("denetimduzeltme", "denetimduzeltme");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "Denetci"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("denetimduzeltme", "denetimduzeltme");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DenetimDuzeltmePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      denetimduzeltme_645727_value: this.state.GunlukCevapById?.at?.(0)?.sonCevapMetni ?? undefined,
      denetimduzeltme_782011_value: this.state.GunlukCevapById?.at?.(0)?.sonCevapMetni ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DenetimDuzeltmePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      soruId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SoruId ?? this.props.screenInputs.soruid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.GunlukCevapId ?? this.props.screenInputs.gunlukcevapid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DenetimDuzeltme/DenetimDuzeltmePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ASoruSelectById = result?.data.aSoruSelectById;
    formVars.denetimduzeltme_728694_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.metin : null
    );
    stateVars.isComp24952Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp382573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GunlukCevapById = result?.data.gunlukCevapById;
    formVars.denetimduzeltme_658505_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonCevapMetni : null
    );
    formVars.denetimduzeltme_645727_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonCevapMetni : null
    );
    formVars.denetimduzeltme_782011_value = ReactSystemFunctions.toString(
      this,
      stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonCevapMetni : null
    );
    stateVars.isComp176331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonDurum : null
        ),
        "OK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp630498Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonDurum : null
        ),
        "NOK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp554489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.GunlukCevapById?.length > 0 ? stateVars.GunlukCevapById[0]?.sonDurum : null
        ),
        "OK"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimDuzeltmePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimDuzeltmePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.denetimduzeltme_728694_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.metin : null
    );

    stateVars.isComp176331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonDurum : null
        ),
        "OK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp630498Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonDurum : null
        ),
        "NOK"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp24952Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Aciklamali"
      ) === true
        ? "visible"
        : "hidden";
    formVars.denetimduzeltme_658505_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
    );

    stateVars.isComp984455Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Imza"
      ) === true
        ? "visible"
        : "hidden";
    formVars.denetimduzeltme_645727_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
    );

    stateVars.isComp704458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "Resimli"
      ) === true
        ? "visible"
        : "hidden";
    formVars.denetimduzeltme_782011_value = ReactSystemFunctions.toString(
      this,
      this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
    );

    stateVars.isComp382573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.soruTipi : null
        ),
        "FileUpload"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp554489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonDurum : null
        ),
        "OK"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DenetimDuzeltmeComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimDuzeltmeComponent_861152_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
        ),
        "CevapDosyasi"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.DenetimDuzeltmeComponent_861152_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.DenetimDuzeltmeComponent_861152_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  DenetimDuzeltmeComponent_861152_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.sonCevapMetni : null
        ),
        null
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "c611c466_2ba3_c337_e649_9aca95e6bd4e_notify",
          this.defaultML,
          "Doya eklenmemiş"
        ),
        "warning",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  DenetimDuzeltmeComponent_433959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "denetimduzeltme_364724_value", "1", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimDuzeltmeComponent_433959_onClick1_();
    });

    return isErrorOccurred;
  };
  DenetimDuzeltmeComponent_433959_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_256785_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "denetimduzeltme_256785_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_364724_value", "value", "", "", "")
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      denetimNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_256785_value", "value", "", "", "")
        ),
        "string"
      ),
      DenetleyenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      gunlukCevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.GunlukCevapId ?? this.props.screenInputs.gunlukcevapid,
        "Guid"
      ),
      durum_0: ReactSystemFunctions.convertToTypeByName("NOK", "string"),
      gunlukId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      ),
      gunlukId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DenetimDuzeltme/DenetimDuzeltmeComponent_433959_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Denetim = result?.data.denetim;
    stateVars.GunSonuGuncelle = result?.data.gunSonuGuncelle;
    stateVars.DenetimTarihiSetEt = result?.data.denetimTarihiSetEt;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimDuzeltmeComponent_433959_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimDuzeltmeComponent_433959_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DenetimDuzeltmeComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "denetimduzeltme_364724_value", "0", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimDuzeltmeComponent_9544_onClick1_();
    });

    return isErrorOccurred;
  };
  DenetimDuzeltmeComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_256785_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "denetimduzeltme_256785_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_364724_value", "value", "", "", "")
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      denetimNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "denetimduzeltme_256785_value", "value", "", "", "")
        ),
        "string"
      ),
      DenetleyenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kullaniciId : null
        ),
        "Guid"
      ),
      gunlukCevapId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.GunlukCevapId ?? this.props.screenInputs.gunlukcevapid,
        "Guid"
      ),
      durum_0: ReactSystemFunctions.convertToTypeByName("OK", "string"),
      gunlukId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      ),
      gunlukId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GunlukCevapById?.length > 0 ? this.state.GunlukCevapById[0]?.checklistGunlukId : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DenetimDuzeltme/DenetimDuzeltmeComponent_9544_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Denetim = result?.data.denetim;
    stateVars.GunSonuGuncelle = result?.data.gunSonuGuncelle;
    stateVars.DenetimTarihiSetEt = result?.data.denetimTarihiSetEt;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimDuzeltmeComponent_9544_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimDuzeltmeComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
