import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDenetimTarihce_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDenetimTarihce_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  MyContext: any[];
  GunlukCevapTarihcesiByCevapId: any[];
  ASoruSelectById: any[];
}

export class DenetimTarihce_ScreenBase extends React.PureComponent<IDenetimTarihce_ScreenProps, any> {
  denetimtarihce_8808449_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "d55fb309-566d-d4ad-d80f-289c38bec69a",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c34dfb89-4a58-43d7-a64b-718ebf3eff54",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Tarihçe" },
        { Id: 194003, PropertyName: "value", Value: "Soru Metni" },
        { Id: 8808449, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı!" },
        { Id: 1703804, PropertyName: "title", Value: "Gunluk cevap id" },
        { Id: 9866579, PropertyName: "value", Value: "[datafield:tamamlanamamanedeni]" },
        { Id: 5852105, PropertyName: "value", Value: "[datafield:cevapmetni]" },
        { Id: 7535871, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 5774143, PropertyName: "value", Value: "[datafield:cevaptarihi]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.denetimtarihce_8808449_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      GunlukCevapTarihcesiByCevapId: [],
      ASoruSelectById: []
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("denetimtarihce", "denetimtarihce");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DenetimTarihcePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("denetimtarihce", "denetimtarihce");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["AvaneAdmin", "SirketAdmin", "Denetci"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("denetimtarihce", "denetimtarihce");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DenetimTarihcePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DenetimTarihcePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      gunlukCevapId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.GunlukCevapId ?? this.props.screenInputs.gunlukcevapid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DenetimTarihce/DenetimTarihcePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GunlukCevapTarihcesiByCevapId = result?.data.gunlukCevapTarihcesiByCevapId;

    stateVars.ASoruSelectById = result?.data.aSoruSelectById;
    formVars.denetimtarihce_728694_value = ReactSystemFunctions.toString(
      this,
      stateVars.ASoruSelectById?.length > 0 ? stateVars.ASoruSelectById[0]?.metin : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DenetimTarihcePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DenetimTarihcePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.denetimtarihce_728694_value = ReactSystemFunctions.toString(
      this,
      this.state.ASoruSelectById?.length > 0 ? this.state.ASoruSelectById[0]?.metin : null
    );

    stateVars.dataSource_8808449 = this.state.GunlukCevapTarihcesiByCevapId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DenetimTarihceComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
