import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Email,
  HorizontalStack,
  KCol,
  KPanel,
  KRow,
  Label,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { ForgotPassword_ScreenBase } from "./forgotpassword-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class ForgotPassword_Screen extends ForgotPassword_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form form={this.props.form} name="basic" initialValues={{ forgotpassword_405510_value: "" }}>
            <div
              id="forgotpassword_body"
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(245, 249, 255, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="777392"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="334216"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KPanel
                    id="470088"
                    scrolling="hidden"
                    scrollRadius={false}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundImage:
                          "url(" +
                          KuikaAppManager.GetBackendUrl() +
                          "/resource/runtime/byid?resourceId=c88eaeef-584b-4f14-095d-08dba5aa559d)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        height: "100vh",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="383525"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="582069"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="171424"
                          scrollRadius={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              boxShadow: "0px 4px nullpx 0px rgba(155, 155, 155, 1)",
                              paddingTop: 100,
                              paddingRight: 24,
                              paddingBottom: 24,
                              paddingLeft: 24,
                              maxWidth: "420px",
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="206640"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                boxShadow: "0px 4px 24px 0px rgba(155, 155, 155, 1)",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 40,
                                paddingRight: 16,
                                paddingBottom: 40,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="529641"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingRight: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left"
                                } as any
                              }
                            >
                              <KRow
                                id="598144"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="911450"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: null,
                                      paddingRight: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="634963"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="574431"
                                      value={ReactSystemFunctions.translate(this.ml, 574431, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "17pt",
                                          color: "rgba(95, 207, 229, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="900580"
                                      value={ReactSystemFunctions.translate(this.ml, 900580, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 300,
                                          fontSize: "14pt",
                                          color: "rgba(155, 155, 155, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>
                              </KRow>

                              <KRow
                                id="103344"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="738916"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingRight: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="forgotpassword_405510_value">
                                    <Email
                                      id="405510"
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        405510,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      disabled={false}
                                      iconColor="rgba(0, 0, 0, 1)"
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)"
                                        } as any
                                      }
                                    ></Email>
                                  </Form.Item>
                                </KCol>
                              </KRow>

                              <KRow
                                id="364760"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 32,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="281350"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingRight: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Button
                                    id="611276"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.ForgotPasswordComponent_611276_onClick();
                                    }}
                                    showCursorPointer
                                    label={ReactSystemFunctions.translate(this.ml, 611276, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    iconPosition="left"
                                    danger={false}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(95, 207, 229, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 16,
                                        paddingBottom: 8,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Button>
                                </KCol>
                              </KRow>

                              <KRow
                                id="889182"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="195061"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingRight: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="746095"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="883899"
                                      value={ReactSystemFunctions.translate(this.ml, 883899, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="520648"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.ForgotPasswordComponent_520648_onClick();
                                      }}
                                      showCursorPointer
                                      value={ReactSystemFunctions.translate(this.ml, 520648, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(95, 207, 229, 1)",
                                          lineHeight: "22px",
                                          textDecoration: "underline"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </KCol>
                    </KRow>
                  </KPanel>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword_Screen))))
  )
);
export { tmp as ForgotPassword_Screen };
//export default tmp;
//export { tmp as ForgotPassword_Screen };
