const screenInputData = {
  TodayCevap: [{"name":"SoruId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CevapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SirketForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CategoryForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DenetimDuzeltme: [{"name":"SoruId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"GunlukCevapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ClReadOnly: [{"name":"IsNew","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"ChecklistId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SubeForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	SoruForm: [{"name":"soruId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"sirketId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ClForm: [{"name":"ChecklistId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SirketId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	KullaniciForm: [{"name":"UserId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEdit","type":"String","cardinality":1,"isExpandedPanel":null}],
	BolgeForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DenetimForm: [{"name":"ChecklistGunlukId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DenetimTarihce: [{"name":"GunlukCevapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DenetimNotlar: [{"name":"GunlukCevapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	NotificationForm: [{"name":"UserId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ClSoruEkleme: [{"name":"ChecklistId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SoruId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"IsDuplicate","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"ChecklistSoruId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	GerekceForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
